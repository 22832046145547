@import 'styles/variables/index.css';

/* Dark theme UI elements */
%font-theme-dark-copy {
  color: var(--color-dark-copy);
}

%font-theme-dark-copy-alternate {
  color: var(--color-dark-copy-alternate);
}

%font-theme-dark-link {
  color: var(--color-dark-link);

  &:hover,
  &:focus {
    color: var(--color-dark-link-hover);
  }
}

%font-theme-dark-label {
  color: var(--color-dark-label);
}

%font-theme-dark-decoration {
  color: var(---color-dark-decoration);
}

%font-theme-dark-disabled {
  color: var(--color-dark-disabled);
}

/* Light theme UI elements */
%font-theme-light-copy {
  color: var(--color-light-copy);
}

%font-theme-light-copy-alternate {
  color: var(--color-light-copy-alternate);
}

%font-theme-light-link {
  color: var(--color-light-link);

  &:hover,
  &:focus {
    color: var(--color-light-link-hover);
  }
}

%font-theme-light-label {
  color: var(--color-light-label);
}

%font-theme-light-decoration {
  color: var(---color-light-decoration);
}

%font-theme-light-disabled {
  color: var(--color-light-disabled);
}

/* UI colours light theme */
/*
--color-light-copy: var(--color-primary);
--color-light-copy-alternate: var(--color-white-darker);
--color-light-label: var(--color-white-darker);
--color-light-link: var(--color-primary);
--color-light-link-hover: var(--color-white-darker);
--color-light-decoration: var(--color-white-darker);
--color-light-disabled: var(--color-grey-60);
*/

/* UI colours dark theme */
/*
--color-dark-copy: var(--color-secondary);
--color-dark-copy-alternate: var(--color-grey-63);
--color-dark-label: var(--color-grey-30);
--color-dark-link: var(--color-grey-20);
--color-dark-link-hover: var(--color-grey-10);
--color-dark-decoration: var(--color-grey-30);
--color-dark-disabled: var(--color-grey-60);
*/
