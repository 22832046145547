@import 'styles/variables/index';
@import 'styles/mixins/index';

.base {
  position: relative;
  overflow: scroll;
  width: 100%;
  max-height: calc(100vh - var(--layout-sm-spacing) * 4);
  padding: calc(var(--layout-sm-spacing) * 2);
  background-color: var(--color-white);
  color: var(--color-grey-10);
  font-family: var(--font-family-sans-serif-regular);
  font-size: rem(15px);
  line-height: 1.6;
  transform: translateZ(0);

  * {
    &,
    &::before,
    &::after {
      box-sizing: inherit;
    }
  }

  @media (--viewport-md) {
    max-height: calc(100vh - var(--layout-md-spacing) * 4 - 50px);
    padding: calc(var(--layout-md-spacing) * 2);
  }

  &.isVisible {
    pointer-events: auto;
  }
}

.closeIcon {
  fill: var(--color-grey-10);
}

.closeButton {
  position: absolute;
  top: var(--layout-sm-spacing);
  right: var(--layout-sm-spacing);
  width: 30px;
  height: 30px;
  padding: 2px 4px 0 4px;

  @media (--viewport-md) {
    top: 18px;
    right: 20px;
  }

  &:focus {
    outline-color: var(--color-grey-20);
  }
}
