@import 'styles/index';

.heading {
  margin: 0 0 10px 0;
  color: var(--color-dark-copy);
  font-family: var(--font-family-sans-serif-medium);

  a:hover &,
  a:focus & {
    text-decoration: underline;
  }
}

.information {
  padding: 0 20px;
}

.description {
  color: var(--color-grey-30);
  font-family: var(--font-family-sans-serif-regular);
  font-size: rem(14px);
}

.image {
  img {
    width: auto;
    max-width: 100%;
    max-height: 100%;
    margin: auto auto 0;
  }
}

.imageContainer {
  margin-top: auto;
}
