@import 'styles/index';

.base {
  position: relative;
  display: flex;
  height: 100%;
  flex-direction: column;
  font-size: rem(15px);

  @media (--viewport-md-only) {
    display: flex;
  }
}

.heading {
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewport-md-only) {
    position: absolute;
    left: 0;
    width: 50%;
    width: calc(50% - var(--layout-md-spacing));
    padding-right: var(--layout-md-spacing);
  }
}

.description {
  max-width: 85%;

  @media (--viewport-md-only) {
    width: 50%;
    max-width: 100%;
    margin-left: 50%;
  }
}

.hasFooter.hasFooter {
  margin-bottom: 20px;
}

.ctaWrapper {
  margin-top: auto;

  @media (--viewport-md-only) {
    width: 50%;
    max-width: 100%;
    margin-left: 50%;
  }
}
