@import 'styles/index';

.base {
  padding: 0;
  margin: rem(20px) 0;
  font-family: var(--font-family-sans-serif-regular);
  font-weight: var(--font-weight-normal);
  line-height: 1.2;
  text-size-adjust: 100%;
}

.dark {
  @extend %font-theme-dark-copy;
}

.light {
  @extend %font-theme-light-copy;
}

.noTopMargin {
  margin-top: 0;
}

.noMargin {
  margin: 0;
}

.serifFont {
  font-family: var(--font-family-zapf-serif);
  font-weight: var(--font-weight-light);
}

.mediumWeightFont {
  font-family: var(--font-family-sans-serif-medium);
}

.xXSmall {
  font-size: rem(14px);
  line-height: 1.7;
}

.xSmall {
  font-size: rem(15px);
  line-height: 1.6;

  @media (--viewport-md) {
    font-size: rem(17px);
  }
}

.small {
  font-size: rem(17px);
  line-height: 1.5;

  @media (--viewport-md) {
    font-size: rem(19px);
  }
}

.medium {
  font-size: rem(20px);
  line-height: 1.4;

  @media (--viewport-md) {
    font-size: rem(22px);
  }
}

.large {
  font-size: rem(23px);
  line-height: 1.4;

  @media (--viewport-md) {
    font-size: rem(25px);
  }

  @media (--viewport-lg) {
    font-size: rem(27px);
  }
}

.xLarge {
  font-size: rem(26px);
  line-height: 1.3;

  @media (--viewport-md) {
    font-size: rem(28px);
  }

  @media (--viewport-lg) {
    font-size: rem(30px);
  }
}
