@import 'styles/index';

.base {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.playsInFullScreen {
  @media (--viewport-lg) {
    position: fixed;
    z-index: var(--z-index-fixed-full-screen-video);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;
    transform: scale(0.95);
    transition: all 600ms;
  }
}

.isPlayingInFullScreen.isPlayingInFullScreen {
  pointer-events: auto;
  transform: scale(1);
}
