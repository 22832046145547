@import 'styles/index';

.base {
  display: inline-block;
}

.picture {
  width: 100%;
  height: auto;
  padding: 0;
  margin: 0;
  text-align: center;

  img {
    width: 100%;
    height: auto;
  }
}

.isRightAligned {
  padding:
    var(--layout-sm-spacing) 0 var(--layout-sm-spacing)
    var(--layout-sm-spacing);
}

.fullBleedImage {
  position: relative;
  width: 100%;
  padding: 0;

  img {
    width: 100%;
  }
}

.captionWrapper {
  padding: 25px 25px 25px 0;
  border-bottom: 1px solid var(--color-grey-60);

  @nest .fullBleedImage & {
    width: 100%;
    padding: 0 var(--layout-sm-spacing);
    border: none;
    margin-top: 25px;

    @media (--viewport-md) {
      width: calc(100% - (100% / 6));
      padding: 0 var(--layout-md-spacing) 25px 0;
      border-bottom: 1px solid var(--color-grey-60);
      margin-left: calc(100% * 1 / 6);
    }

    @media (--viewport-lg) {
      width: calc(100% - (100% * 5 / 12) + 40px);
      padding: 0 80px 25px 0;
      margin-left: calc(100% * 5 / 12 - 40px);
    }
  }

  @media (--viewport-md) {
    padding: 25px 25px 25px var(--layout-md-spacing);
  }

  @media (--viewport-lg) {
    padding: 25px 25px 25px 20px;
  }
}

.caption {
  margin: 0;
  color: var(--color-grey-30);
  font-family: var(--font-family-sans-serif-regular);
  font-size: rem(15px);
  line-height: 1.8;
}
