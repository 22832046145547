@import 'styles/index';

.base {
  box-sizing: border-box;

  * {
    &,
    &::before,
    &::after {
      box-sizing: inherit;
    }
  }

  @media (--viewport-md) {
    display: flex;
    flex-wrap: wrap;
  }
}

.subHeading {
  font-family: var(--font-family-sans-serif-medium);
}

.column {
  padding-right: var(--layout-sm-spacing);

  @media (--viewport-md) {
    &:not(:last-child) {
      padding-right: var(--layout-sm-spacing);
    }
  }

  @media (--viewport-lg) {
    &:not(:last-child) {
      padding-right: var(--layout-sm-spacing);
    }
  }

  @media (--viewport-md) {
    max-width: 50%;
    flex: 0 0 50%;
  }
}

.wrapper {
  margin-bottom: var(--layout-sm-spacing);

  @media (--viewport-md) {
    margin-bottom: var(--layout-md-spacing);
  }

  @media (--viewport-ld) {
    margin-bottom: var(--layout-ld-spacing);
  }
}

.dark {
  @extend %font-theme-dark-alternate;

  a {
    /* @extend %font-theme-dark-link; */
    color: var(--color-dark-link);

    &:hover,
    &:focus {
      color: var(--color-dark-link-hover);
    }
  }
}

.light.light {
  @extend %font-theme-light-alternate;

  a {
    /* @extend %font-theme-light-link; */
    color: var(--color-light-link);

    &:hover,
    &:focus {
      color: var(--color-light-link-hover);
    }
  }
}

.list {
  font-size: 15px;

  /* stylelint-disable */
  li {
    a {
      font-size: 15px;
      text-decoration: none;

      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }

    li {
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }
  /* stylelint-enable */
}
