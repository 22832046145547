@import 'styles/index';

.fullScreenControls {
  @media (--viewport-lg) {
    position: fixed;
    z-index: var(--z-index-fixed-full-screen-video);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;
    transform: scale(0.95);
    transition: all 800ms;
  }
}

.videoTitle {
  position: absolute;
  top: calc(var(--layout-sm-spacing) * 2);
  left: calc(var(--layout-sm-spacing) * 2);

  @media (--viewport-md) {
    top: calc(var(--layout-md-spacing) * 2);
    left: calc(var(--layout-md-spacing) * 2);
  }

  @media (--viewport-lg) {
    top: calc(var(--layout-lg-spacing) * 2);
    left: calc(var(--layout-lg-spacing) * 2);
  }
}

.fullScreenControlsHeader {
  opacity: 1;
  pointer-events: auto;
  transition: opacity 500ms var(--easing-ease-in-sine);
}

.activeFullScreenControls.activeFullScreenControls {
  pointer-events: auto;
  transform: scale(1);
}

.mute {
  position: absolute;
  z-index: calc(var(--z-index-fixed-full-screen-video) + 1);
  top: calc(var(--layout-sm-spacing) * 2);
  left: calc(var(--layout-sm-spacing) * 2);
  padding: 7px;
  background-color: var(--color-grey-40);
  border-radius: 100%;
  line-height: 0;
  opacity: 0.8;
  transition: opacity 800ms var(--easing-ease-in-sine);

  &:hover,
  &:focus {
    opacity: 1;
  }

  @media (--viewport-md) {
    top: calc(var(--layout-md-spacing) * 2);
    left: calc(var(--layout-md-spacing) * 2);
  }

  @media (--viewport-lg) {
    top: calc(var(--layout-lg-spacing) * 2);
    left: calc(var(--layout-lg-spacing) * 2);
  }
}

.close {
  position: absolute;
  top: calc(var(--layout-sm-spacing) * 2);
  right: calc(var(--layout-sm-spacing) * 2);
  padding-right: 4px;
  color: var(--color-light-link);
  font-size: rem(17px);
  opacity: 0.8;
  transition: opacity 200ms var(--easing-ease-in-sine);

  &:hover,
  &:focus {
    opacity: 1;
  }

  svg {
    fill: var(--color-light-link);
  }

  &:hover svg,
  &:focus svg {
    fill: var(--color-light-link-hover);
  }

  @media (--viewport-md) {
    top: calc(var(--layout-md-spacing) * 2);
    right: calc(var(--layout-md-spacing) * 2);
  }

  @media (--viewport-lg) {
    top: calc(var(--layout-lg-spacing) * 2);
    right: calc(var(--layout-lg-spacing) * 2);
  }
}

.playPauseButton {
  position: absolute;
  top: calc(50% - 15px);
  left: calc(50% - 15px);
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: var(--color-light-link-hover);
  border-radius: 100%;
  color: var(--color-light-link);
  opacity: 0.8;
  transition: opacity 800ms var(--easing-ease-in-sine);

  &:hover,
  &:focus {
    color: var(--color-light-link-hover);
    opacity: 1;
  }
}

.activePlayPauseButton.activePlayPauseButton {
  background-color: var(--color-grey-40);

  svg {
    fill: var(--color-light-link);
  }
}

.inactivePlayPauseButton {
  display: none;
}

.fullScreenPlayPauseButton.fullScreenPlayPauseButton {
  position: absolute;
  z-index: var(--z-index-fixed-full-screen-video);
  top: calc(50vh - 40px - 13px);
  left: calc(50vw - 40px - 13px);
  padding: 10px;
  background-color: var(--color-grey-40);
  border-radius: 100%;
  line-height: 0;
  opacity: 0.8;
  transition: opacity 200ms var(--easing-ease-in-sine);

  svg {
    fill: var(--color-light-link);
  }

  &:hover,
  &:focus {
    opacity: 0.9;
  }
}

.playPauseButtonIcon.playPauseButtonIcon {
  display: flex;
  width: 30px;
  height: 30px;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  background-color: var(--color-light-link);
  border-radius: 100%;

  &:hover,
  &:focus {
    color: var(--color-light-link-hover);
  }

  svg {
    fill: var(--color-dark-background);
  }
}

.playButtonIcon {
  transform: translateX(1px);
}

.progressBar {
  position: absolute;
  z-index: var(--z-index-fixed-full-screen-video);
  right: calc(var(--layout-lg-spacing) * 2);
  bottom: 90px;
  left: calc(var(--layout-lg-spacing) * 2);
  display: block;
  overflow: hidden;
  height: 3px;
  margin: 0;
  background-color: var(--color-white-rgba-20);

  @media (--viewport-sm-only) {
    right: calc(var(--layout-sm-spacing) * 2);
    bottom: 30px;
    left: calc(var(--layout-sm-spacing) * 2);
  }

  @media (--viewport-md-only) {
    right: calc(var(--layout-md-spacing) * 2);
    bottom: 48px;
    left: calc(var(--layout-md-spacing) * 2);
  }

  @media (--viewport-lg) {
    position: fixed;
  }
}

.progress {
  width: 0;
  height: 3px;
  background-color: var(--color-white);
  content: '';
  transition: width 100ms var(--easing-ease-in-sine);
}

.hidden.hidden {
  opacity: 0;
  pointer-events: none;
}
