@import 'styles/index';

.base {
  margin: var(--layout-sm-spacing) 0;

  @media (--viewport-md) {
    margin: var(--layout-md-spacing) 0;
  }

  @media (--viewport-lg) {
    margin: var(--layout-lg-spacing) 0;
  }
}

.heading {
  font-family: var(--font-family-sans-serif-regular);
  line-height: 1.6;
  transition: all 300ms cubic-bezier(0.215, 0.61, 0.355, 1);

  .dark & {
    @extend %font-theme-dark-label;
  }

  .light & {
    color: var(--color-light-label);
  }
}

.itemList {
  font-family: var(--font-family-sans-serif-medium);
  font-size: rem(17px);
}
