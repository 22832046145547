@import 'styles/index';

.base {
  display: flex;
  align-items: center;
  padding: 60px var(--layout-sm-spacing);

  @media (--viewport-md) {
    padding: 100px var(--layout-md-spacing);
  }

  @media (--viewport-lg) {
    padding: 160px var(--layout-lg-spacing);
  }
}

.dark {
  @extend %font-theme-dark-copy;
}

.light {
  @extend %font-theme-light-copy;
}

.noTopPadding {
  padding-top: 0;
}

.wrapper {
  margin: 0 auto;
  text-align: center;

  @media (--viewport-md) {
    @mixin grid-pct 3, 6;
  }

  @media (--viewport-lg) {
    @mixin grid-pct 6, 12;
  }
}

.blockquote {
  padding: 0;
  margin: rem(20px) 0;
  font-family: var(--font-family-zapf-serif);
  font-size: rem(27px);
  font-weight: var(--font-weight-normal);
  line-height: 1.2;
  text-size-adjust: 100%;
}

.author {
  display: block;
  margin-top: 24px;
  font-family: var(--font-family-sans-serif-medium);
  font-size: rem(15px);
  font-style: initial;
  line-height: 1.4;

  @media (--viewport-md) {
    margin-top: 34px;
  }

  @media (--viewport-lg) {
    margin-top: 48px;
  }
}
