@import 'styles/variables/index';
@import url('https://d8ejoa1fys2rk.cloudfront.net/bynder-embed/2.13/css/bynder-widgets.min.css');

.heading {
  position: absolute;
  z-index: 9;
  margin-top: 16px;
  margin-left: 18px;
  font-size: rem(15px);
  font-weight: bold;
}

.base {
  :global {
    .bynder-widget-media-list {
      border: 1px solid #ddd !important;
      margin-bottom: 50px;
      border-radius: 0 !important;
    }

    .bynder-widget-media-list .bynder-widget-header,
    .bynder-widget-media-list .bynder-widget-content {
      border: none !important;
      background: #fffef2 !important;
      box-shadow: none !important;
    }

    a.bynder-widget-btn {
      border: 1px solid #333 !important;
      background: #333 !important;
      border-radius: 0 !important;
      box-shadow: 0 0 #333 !important;
    }

    a.bynder-widget-btn:hover {
      background: #333 !important;
    }

    div.media-item a.bynder-widget-media-link,
    ul.bynder-widget-media-items li a.bynder-widget-media-link {
      width: 80% !important;
      border: 1px solid #ddd !important;
      background: #fffef2 !important;
      box-shadow: 0 0 transparent !important;
    }

    ul.bynder-widget-media-items li a.bynder-widget-media-link {
      border-radius: 0 !important;
    }

    div.media-item:hover a.bynder-widget-media-link,
    ul.bynder-widget-media-items li:hover a.bynder-widget-media-link {
      padding: 10px !important;
      border: 2px solid #333 !important;
      box-shadow: 0 0 transparent !important;
    }

    .bynder-widget-media-list .bynder-widget-header a.bynder-widget-link {
      visibility: hidden !important;
    }

    div.media-item a p,
    ul.bynder-widget-media-items li a p {
      width: 100% !important;
    }

    ul.bynder-widget-media-items li a .bynder-widget-mediaholder {
      width: 100% !important;
      background: transparent !important;
    }

    .bynder-widget-header a.bynder-widget-btn {
      right: 18px !important;
    }

    ul.bynder-widget-media-items li a.bynder-widget-btn {
      top: 0 !important;
      right: 0 !important;
      bottom: 0;
      left: 0;
      width: 70% !important;
      margin: auto !important;
    }

    ul.bynder-widget-media-items li a.bynder-widget-btn:hover {
      width: 70% !important;
    }

    .bynder-widget-media-list .bynder-widget-content {
      height: auto !important;
    }
  }
}
