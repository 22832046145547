@import 'styles/index';

.base {
  outline: none;

  path {
    transition: fill 200ms var(--easing-ease-in-out-cubic);
  }

  &.dark {
    fill: var(--color-secondary);
  }

  &.light {
    fill: var(--color-primary);
  }
}

.plusAndCloseWithCircle {
  transition: all 200ms var(--easing-ease-in-out-cubic);

  .circle {
    fill: transparent;
    stroke-width: 3px;
    transition: fill 200ms var(--easing-ease-in-out-cubic);

    &.dark {
      stroke: var(--color-secondary);
    }

    &.light {
      stroke: var(--color-primary);
    }
  }

  .plus {
    opacity: 1;
    stroke-width: 2px;
    transition:
      fill 200ms var(--easing-ease-in-out-cubic),
      stroke 200ms var(--easing-ease-in-out-cubic),
      opacity 300ms var(--easing-ease-in-out-cubic);

    &.dark {
      fill: var(--color-secondary);
      stroke: var(--color-secondary);
    }

    &.light {
      fill: var(--color-primary);
      stroke: var(--color-primary);
    }
  }

  .close {
    opacity: 0;
    stroke-width: 2px;
    transition: opacity 300ms var(--easing-ease-in-out-cubic);

    &.dark {
      fill: var(--color-secondary);
      stroke: var(--color-secondary);
    }

    &.light {
      fill: var(--color-primary);
      stroke: var(--color-primary);
    }
  }
}
