@define-mixin grid-pct $occupied: 1, $total: 1, $percent: 100% {
  /* flex-basis doesn't account for box-sizing:border-box */
  /* stylelint-disable */
  width: calc($(percent) * $(occupied) / $(total));
  flex-basis: auto;

  /* IE is consistently 1px too wide with the standard calc value */
  /* here is a hack so terrible we have to tell the linter to ignore it */

  _:-ms-input-placeholder,
  :root & {
    width: calc(($(percent) * $(occupied) / $(total)) - 1px);
  }
  /* stylelint-enable */
}
