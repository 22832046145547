@import 'styles/index';

.base {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 0;
  margin: 0;
  object-fit: cover;

  @media (--viewport-sm-md-only) {
    height: 0;
    padding-top: 30px;
    padding-bottom: 56.25%; /* 16:9 */
  }

  @media (--viewport-lg) {
    height: auto;
    padding-top: 0;
    padding-bottom: 100%;
  }
}

.fullWidth {
  @media (--viewport-lg) {
    height: 0;
    padding-top: 30px;
    padding-bottom: 56.25%; /* 16:9 */
  }
}

.fallbackImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;

  &,
  picture,
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
