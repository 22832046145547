@import 'styles/index';

.base {
  position: relative;
  padding: 0;
  border: 0;
  appearance: none;
  background-color: transparent;
  cursor: pointer;
  font-family: var(--font-family-sans-serif-regular);
  font-size: rem(15px);
  font-weight: var(--font-weight-normal);
  outline: none;
  text-decoration: none;

  > * {
    pointer-events: none;
  }
}

.blockStyle {
  display: inline-block;
  min-width: 210px;
  max-width: 400px;
  padding: 20px;
  border-width: 1px;
  border-style: solid;
  transition: all 200ms var(--easing-ease-in-out-cubic);
}

.dark {
  @extend %font-theme-dark-link;

  &.blockStyle {
    border-color: var(--color-dark-background);

    &:hover,
    &:focus {
      background-color: var(--color-dark-background);
      color: var(--color-light-link-hover);
    }

    &.alternate {
      border-color: var(--color-dark-background);
      background-color: var(--color-dark-background);
      color: var(--color-light-link-hover);

      &:hover,
      &:focus {
        border-color: var(--color-black);
        background-color: var(--color-black);
      }
    }
  }
}

.light.light {
  @extend %font-theme-light-link;

  &.blockStyle {
    border-color: var(--color-white);

    &:focus,
    &:hover {
      background-color: var(--color-white);
      color: var(--color-dark-copy);
    }
  }

  &.alternate {
    border-color: var(--color-light-link-hover);
    background-color: var(--color-light-link-hover);
    color: var(--color-dark-copy);

    &:hover,
    &:focus {
      border-color: var(--color-white);
      background-color: var(--color-white);
    }
  }
}

.disabled {
  background-color: transparent;
  cursor: default;

  &.dark {
    color: var(--color-dark-disabled);

    &.blockStyle {
      border-color: var(--color-dark-disabled);
    }

    &:hover,
    &:focus {
      background-color: transparent;
      color: var(--color-dark-disabled);

      &.blockStyle {
        border-color: var(--color-dark-disabled);
      }
    }
  }

  &.light.light {
    color: var(--color-light-disabled);

    &.blockStyle {
      border-color: var(--color-light-disabled);
    }

    &:hover,
    &:focus {
      background-color: transparent;
      color: var(--color-light-disabled);

      &.blockStyle {
        border-color: var(--color-light-disabled);
      }
    }
  }
}
