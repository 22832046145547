@import 'styles/variables/index';

.slideRightEnter {
  transform: translate3d(-100%, 0, 0);
  visibility: hidden;
}

.slideRightEnterActive {
  transform: translate3d(0, 0, 0);
  transition:
    transform 500ms var(--easing-ease-out-cubic),
    visibility 500ms var(--easing-ease-out-cubic);
  visibility: visible;
}

.slideRightEnterDone {
  transform: translate3d(0, 0, 0);
  visibility: visible;
}

.slideRightExit {
  transform: translate3d(0, 0, 0);
  visibility: visible;
}

.slideRightExitActive {
  transform: translate3d(-100%, 0, 0);
  transition:
    transform 500ms var(--easing-ease-out-cubic),
    visibility 500ms var(--easing-ease-out-cubic);
  visibility: hidden;
}

.slideRightExitDone {
  transform: translate3d(-100%, 0, 0);
  visibility: hidden;
}
