@import 'styles/variables/index';
@import 'styles/mixins/index';

.base {
  /* @extend %layout-full-bleed-negative-margin; */

  position: relative;
  box-sizing: border-box;
  margin-bottom: var(--layout-md-spacing);

  * {
    &,
    &::before,
    &::after {
      box-sizing: inherit;
    }
  }

  @media (--viewport-lg) {
    margin-bottom: calc(var(--layout-lg-spacing) * 1.5);
  }
}

.backgroundImagePrototype {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.noTopMargin {
  margin-top: 0;
}

.image {
  @media (--viewport-md) {
    position: absolute;
    top: 0;
    left: 0;
    padding: 0;
    background-color: transparent;
  }
}

.contentWrapper {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  padding: calc(var(--layout-sm-spacing) * 2);
}

.content {
  @media (--viewport-md) {
    @mixin grid-pct 7, 12;

    padding-top:
      calc(
        (var(--layout-logo-offset-md) + var(--layout-menu-height) - 10px)
      );
    margin-left: calc((100% * 4 / 12));
  }

  @media (--viewport-lg) {
    @mixin grid-pct 5, 12;

    padding-top:
      calc(
        (var(--layout-logo-offset-lg) + var(--layout-menu-height) - 5px)
      );
    margin-left: calc((100% / 12) * 3);
  }
}

.primaryTitle,
.secondayTitle {
  margin: 0;
}
