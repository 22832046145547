@import 'styles/index';

.base {
  font-family: var(--font-family-sans-serif-regular);

  &:not(:last-child) {
    margin-bottom: var(--layout-sm-spacing);
  }

  @media (--viewport-md) {
    &:not(:last-child) {
      margin-bottom: var(--layout-md-spacing);
    }
  }

  @media (--viewport-ld) {
    &:not(:last-child) {
      margin-bottom: var(--layout-ld-spacing);
    }
  }
}

.ctaWrapper {
  margin-top: calc(var(--layout-sm-spacing) / 2);

  @media (--viewport-md) {
    margin-top: calc(var(--layout-md-spacing) / 2);
  }

  @media (--viewport-lg) {
    margin-top: calc(var(--layout-md-spacing) / 2);
  }

  a {
    display: inline-block;
    margin-top: calc(var(--layout-sm-spacing) / 2);

    @media (--viewport-md) {
      margin-top: calc(var(--layout-md-spacing) / 2);
    }

    @media (--viewport-lg) {
      margin-top: calc(var(--layout-md-spacing) / 2);
    }
  }
}

.copy {
  &,
  p {
    margin: 10px 0;
    font-size: rem(17px);
    line-height: 1.7;

    &:first-of-type {
      margin-top: 0;
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    a {
      font-weight: var(--font-weight-normal);
    }
  }
}

.dark {
  &.copy {
    &,
    p {
      @extend %font-theme-dark-copy;
    }

    a {
      /* @extend %font-theme-dark-link; */
      color: var(--color-dark-link);

      &:hover,
      &:focus {
        color: var(--color-dark-link-hover);
      }
    }
  }
}

.light.light {
  &.copy {
    &,
    p {
      @extend %font-theme-light-copy;
    }

    a {
      /* @extend %font-theme-light-link; */
      color: var(--color-light-link);

      &:hover,
      &:focus {
        color: var(--color-light-link-hover);
      }
    }
  }
}

/* .HeroWithContent {
  .heading,
  .subHeading {
    margin: 0;
  }

  .subHeading {
    padding-top: 5px;
  }

  .copy.copy {
    margin: 30px 0 0;

    a {
      width: 100%;
      margin-top: 30px;

      @media (--viewport-md) {
        width: auto;
      }
    }
  }
}

.TwoColumn {
  .heading {
    font-family: var(--font-family-zapf-serif);
  }
}

.HalfWidthFullBleedHero {
  .eyebrow {
    padding: 0 0 19px;

    @media (--viewport-lg) {
      padding: 0 0 14px;
      margin-top: 60px;
    }

    @media (--viewport-xl) {
      padding: 0 0 19px;
    }
  }

  .heading {
    padding: 0 0 17px 0;
    border-bottom: 2px solid var(--color-grey-10);
    font-family: var(--font-family-zapf-serif);

    @media (--viewport-md) {
      padding: 0 0 12px;
    }

    @media (--viewport-xl) {
      padding: 0 0 17px;
    }
  }
} */
