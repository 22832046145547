@import 'styles/variables/index';
@import 'styles/mixins/index';

.base {
  position: fixed;
  z-index: var(--z-index-overlay);
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--color-black-rgba-50);
  pointer-events: none;

  &.isVisible {
    pointer-events: auto;
  }
}
