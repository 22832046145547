@import 'styles/variables/index';
@import 'styles/mixins/index';

.base {
  position: fixed;
  z-index: var(--z-index-flyin-panel);
  top: 0;
  bottom: 0;
  left: 0;
  justify-content: center;
  padding:
    calc(var(--layout-sm-spacing) * 3) calc(var(--layout-sm-spacing) * 2)
    calc(var(--layout-sm-spacing) * 2);
  background-color: var(--color-white-dark);
  font-family: var(--font-family-sans-serif-regular);
  font-size: rem(15px);
  line-height: 1.4;
  overflow-y: auto;

  @media (--viewport-sm-only) {
    width: auto;
  }

  @media (--viewport-md) {
    width: 50%;
    padding:
      calc(var(--layout-md-spacing) * 3) var(--layout-md-spacing)
      var(--layout-md-spacing);
  }

  @media (--viewport-lg) {
    padding:
      calc(var(--layout-lg-spacing) * 3)
      calc(var(--layout-lg-spacing) * 4) var(--layout-lg-spacing)
      var(--layout-lg-spacing);
  }
}

.closeButton.closeButton {
  position: absolute;
  top: 18px;
  right: 20px;
  padding: 2px 4px 0 4px;
}
