@import 'styles/index';

.base {
  @mixin reset-list;
}

.item {
  position: relative;
  padding-top: 26px;
  padding-bottom: 26px;
  margin: 0;
  font-family: var(--font-family-sans-serif-medium);
  font-size: rem(15px);
  line-height: 1.4;
  opacity: 0;
  transform: translateY(-20px);
  transition-duration: 1000ms;
  transition-property: all;
  transition-timing-function: var(--easing-ease-out-cubic);

  &:nth-of-type(1) {
    transition-delay: calc((1 * 100ms) + 200ms);
  }

  &:nth-of-type(2) {
    transition-delay: calc((2 * 100ms) + 200ms);
  }

  &:nth-of-type(3) {
    transition-delay: calc((3 * 100ms) + 200ms);
  }

  &:nth-of-type(4) {
    transition-delay: calc((4 * 100ms) + 200ms);
  }

  .dark & {
    color: var(--color-dark-copy);

    &:not(:last-child) {
      border-bottom: 1px solid var(--color-grey-65);
      margin: 0;
    }
  }

  .light.light & {
    color: var(--color-light-copy);

    &:not(:last-child) {
      border-bottom: 1px solid var(--color-grey-65);
      margin: 0;
    }
  }

  @media (--viewport-md) {
    padding-top: 14px;
    padding-bottom: 14px;
    line-height: 1.6;
  }
}

.slideIn {
  opacity: 1;
  transform: translateY(0);
}
