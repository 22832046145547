@import 'styles/index';

.base {
  padding-top: 60px;
  padding-bottom: 130px;

  @media (--viewport-md) {
    padding-top: 130px;
  }

  @media (--viewport-lg) {
    padding-top: 100px;
    padding-bottom: 60px;
  }
}

.slideWrapper {
  height: 100%;
}

.carousel {
  position: relative;
  z-index: var(--z-index-forground-content);
  overflow: hidden;

  picture {
    display: flex;
    height: 380px;
    padding: 0;

    @media (--viewport-md) {
      height: 380px;
    }

    @media (--viewport-lg) {
      height: 400px;
    }
  }

  p {
    font-size: rem(15px);
    font-weight: 400;
    line-height: 1.7;
  }

  :global {
    .slick-track {
      display: flex;
    }

    .slick-list {
      position: relative;
      margin: 0 0 24px;

      @media (--viewport-md) {
        margin: 0 40px 24px;
      }

      @media (--viewport-lg) {
        margin: 0 80px 48px;
      }
    }

    .slick-slide {
      & > div {
        height: 100%;
      }
    }
  }
}

/* .edges {
  :global {
    .slick-list {
      &::before,
      &::after {
        position: absolute;
        z-index: var(--z-index-carousel-edge);
        top: 0;
        width: 40px;
        height: 100%;
        content: '';
      }

      &::before {
        left: -40px;
        background:
          -webkit-gradient(
            linear,
            left top,
            right top,
            from(rgb(255, 254, 240)),
            to(rgba(0, 0, 0, 0))
          );
      }

      &::after {
        right: -40px;
        background:
          -webkit-gradient(
            linear,
            right top,
            left top,
            from(rgb(255, 254, 240)),
            to(rgba(0, 0, 0, 0))
          );
      }
    }
  }
} */

.item {
  display: flex;
  flex-direction: column-reverse;
  text-align: center;
  transition:
    background-color 700ms var(--easing-ease-out-cubic),
    color 700ms var(--easing-ease-out-cubic);

  a:active,
  a:hover,
  a:focus {
    background-color: var(--color-white-dark);
  }
}

.link {
  text-decoration: none;

  &:hover,
  &:focus {
    color: var(--color-black);
  }
}

.mobileCarouselIntroductionWrapper {
  margin: 0 20px 60px;

  @media (--viewport-md) {
    margin: 0 40px 100px;
  }

  p {
    max-width: 100%;
    font-size: rem(15px);
  }
}
