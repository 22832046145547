@import 'styles/index';

.base {
  margin: 0;
}

.caption {
  margin: 0;
  font-family: var(--font-family-sans-serif-regular);
  font-size: rem(14px);
  line-height: 1.6;

  .dark & {
    color: var(--color-dark-copy);
  }

  .light & {
    color: var(--color-light-copy);
  }

  p {
    &:first-of-type {
      margin-top: 0;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.captionWrapper {
  padding-top: var(--layout-sm-spacing);
  padding-right: var(--layout-sm-spacing);
  padding-bottom: var(--layout-sm-spacing);
  margin: 0;

  @media (--viewport-md) {
    padding-top: calc(var(--layout-md-spacing) / 2);
    padding-right: var(--layout-md-spacing);
    padding-bottom: calc(var(--layout-md-spacing) / 2);
  }

  @media (--viewport-lg) {
    padding-top: calc(var(--layout-lg-spacing) / 2);
    padding-right: var(--layout-lg-spacing);
    padding-bottom: calc(var(--layout-lg-spacing) / 2);
  }

  /* .fullBleedImage & {
    width: 100%;
    padding: 0 var(--layout-sm-spacing);
    border: none;
    margin-top: 25px;

    @media (--viewport-md) {
      width: calc(100% - (100% / 6));
      padding: 0 var(--layout-md-spacing) 25px 0;
      border-bottom: 1px solid var(--color-grey-60);
      margin-left: calc(100% * 1 / 6);
    }

    @media (--viewport-lg) {
      width: calc(100% - (100% * 5 / 12) + 40px);
      padding: 0 80px 25px 0;
      margin-left: calc(100% * 5 / 12 - 40px);
    }
  } */
}

.border {
  border-bottom: 1px solid var(--color-grey-60);
}
