@import 'styles/index';

.base {
  @mixin reset-list;
}

.term,
.description {
  font-size: rem(15px);
  line-height: 1.4;
  opacity: 0;
  transform: translateY(-20px);
  transition-duration: 1000ms;
  transition-property: all;
  transition-timing-function: var(--easing-ease-out-cubic);

  @media (--viewport-md) {
    line-height: 1.6;
  }
}

.term {
  position: relative;
  padding-top: 26px;
  margin-bottom: 4px;
  font-family: var(--font-family-sans-serif-medium);

  .dark & {
    @extend %font-theme-dark-copy;
  }

  .light & {
    color: var(--color-light-copy);
  }

  &:nth-of-type(1) {
    transition-delay: calc((1 * 100ms) + 200ms);
  }

  &:nth-of-type(2) {
    transition-delay: calc((2 * 100ms) + 200ms);
  }

  &:nth-of-type(3) {
    transition-delay: calc((3 * 100ms) + 200ms);
  }

  &:nth-of-type(4) {
    transition-delay: calc((4 * 100ms) + 200ms);
  }

  @media (--viewport-md) {
    padding-top: 14px;
  }
}

.slideIn {
  opacity: 1;
  transform: translateY(0);
}

.description {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 26px;
  margin-left: 0;
  font-family: var(--font-family-sans-serif-regular);

  .dark & {
    @extend %font-theme-dark-label;
  }

  .light & {
    color: var(--color-light-label);
  }

  &:nth-of-type(1) {
    transition-delay: calc((1 * 100ms) + 200ms);
  }

  &:nth-of-type(2) {
    transition-delay: calc((2 * 100ms) + 200ms);
  }

  &:nth-of-type(3) {
    transition-delay: calc((3 * 100ms) + 200ms);
  }

  &:nth-of-type(4) {
    transition-delay: calc((4 * 100ms) + 200ms);
  }

  @media (--viewport-md) {
    padding-bottom: 14px;
  }

  &:not(:last-child) {
    border-bottom: 1px solid var(--color-grey-65);
  }
}
