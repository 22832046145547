@import 'styles/variables/index';

.slideDownEnter {
  transform: translate3d(0, -100%, 0);
  visibility: hidden;
}

.slideDownEnterActive {
  transform: translate3d(0, 0, 0);
  transition:
    transform 500ms var(--easing-ease-out-cubic),
    visibility 500ms var(--easing-ease-out-cubic);
  visibility: visible;
}

.slideDownEnterDone {
  transform: translate3d(0, 0, 0);
  visibility: visible;
}

.slideDownExit {
  transform: translate3d(0, 0, 0);
  visibility: visible;
}

.slideDownExitActive {
  transform: translate3d(0, -100%, 0);
  transition:
    transform 500ms var(--easing-ease-out-cubic),
    visibility 500ms var(--easing-ease-out-cubic);
  visibility: hidden;
}

.slideDownExitDone {
  transform: translate3d(0, -100%, 0);
  visibility: hidden;
}
