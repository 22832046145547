@import 'styles/index';

.base {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;

  &,
  picture,
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.isActive {
  pointer-events: auto;
}
