@import 'styles/index';

.base {
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
  list-style: none;
}

.dark {
  color: var(--color-dark-copy);
}

.light {
  color: var(--color-light-copy);
}

.item {
  &:not(:last-child) {
    margin-bottom: var(--layout-sm-spacing);
  }
}
