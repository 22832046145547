@import 'styles/index';

.base {
  display: flex;
  width: 100%;
  height: 7px;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
  transition: opacity 50ms var(--easing-ease-in-out-cubic);
}

.small {
  height: 7px;
}

.isLoading {
  opacity: 1;
  transition: opacity 200ms var(--easing-ease-in-out-cubic);
}

.dot {
  display: block;
  width: 7px;
  height: 7px;
  margin: 0 3px;
  border-radius: 100%;

  .small & {
    width: 5px;
    height: 5px;
  }

  .dark & {
    background: var(--color-grey-20);
  }

  .light & {
    background: var(--color-white);
  }

  @keyframes fadeInOut {
    0% {
      opacity: 0;
    }

    60% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }

  .isLoading & {
    animation: fadeInOut 1000ms var(--easing-ease-in-out-cubic) infinite;

    &:nth-of-type(1) {
      animation-delay: -50ms;
    }

    &:nth-of-type(2) {
      animation-delay: 50ms;
    }

    &:nth-of-type(3) {
      animation-delay: 150ms;
    }
  }
}
