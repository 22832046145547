@import 'styles/index';

.base {
  position: relative;
}

.list {
  position: relative;
  display: flex;
  height: 2px;
  padding: 0;
  margin: 0 20px 40px;
  background-color: var(--color-grey-60);
  list-style-type: none;

  @media (--viewport-md) {
    margin: 0 40px 40px;
  }

  @media (--viewport-lg) {
    margin: 0 80px 40px;
  }

  :global {
    .slick-active {
      background-color: var(--color-secondary);
    }
  }

  li {
    flex-grow: 1;
    margin: 0;
    transition: background-color 700ms var(--easing-ease-out-cubic);
  }
}

.bar {
  position: absolute;
  height: 2px;
  padding: 0;
  margin: 0;
  background-color: var(--color-secondary);
  transition: left 1000ms var(--easing-ease-out-cubic);
}
