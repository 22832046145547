@import 'styles/index';

.paragraph {
  margin: rem(18px) 0;
  font-size: rem(15px);
  line-height: 1.6;

  &:first-of-type {
    margin-top: 0;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
}

.base {
  @extend .paragraph;

  font-family: var(--font-family-sans-serif-regular);
}

.dark {
  &,
  & p {
    @extend %font-theme-dark-copy;
  }
}

.light {
  &,
  & p {
    @extend %font-theme-light-copy;
  }
}

.set {
  p {
    @extend .paragraph;
  }
}
