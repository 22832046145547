@import 'styles/variables/index';

.fadeEnter {
  opacity: 0;
}

.fadeEnterActive {
  opacity: 1;
  transition: opacity 500ms var(--easing-ease-out-cubic);
}

.fadeEnterDone {
  opacity: 1;
}

.fadeExit {
  opacity: 1;
}

.fadeExitActive {
  opacity: 0;
  transition: opacity 500ms var(--easing-ease-out-cubic);
}

.fadeExitDone {
  opacity: 0;
}
