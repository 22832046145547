@import 'styles/variables/index';

.shiftInLeftEnter {
  opacity: 0;
  transform: translate3d(20px, 0, 0);
}

.shiftInLeftEnterActive {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition:
    transform 1500ms var(--easing-ease-out-cubic),
    opacity 1000ms var(--easing-ease-in-cubic);
}

.shiftInLeftEnterDone {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.shiftInLeftExit {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.shiftInLeftExitActive {
  opacity: 0;
  transform: translate3d(20px, 0, 0);
  transition:
    transform 1500ms var(--easing-ease-out-cubic),
    opacity 1000ms var(--easing-ease-in-cubic);
}

.shiftInLeftExitDone {
  opacity: 0;
  transform: translate3d(20px, 0, 0);
}
