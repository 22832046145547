@import 'styles/index';

.base {
  position: relative;
  background-color: var(--color-white-dark);

  @media (--viewport-lg) {
    display: flex;
    min-height: 100vh;
    flex-direction: row;
  }
}

.reverse {
  @media (--viewport-lg) {
    display: flex;
    flex-direction: row-reverse;
  }
}

.content {
  @media (--viewport-md-only) {
    width: 50%;
    flex-basis: auto;
    margin-left: 20px;
  }
}

/* .original.dark .content {
  border-top: 2px solid var(--color-grey-20);
}

.original.light .content {
  border-top: 2px solid var(--color-light-copy);
}

.dark.reverse .heading {
  padding-bottom: 20px;
  border-bottom: 2px solid var(--color-grey-20);
}

.light.reverse .heading {
  padding-bottom: 20px;
  border-bottom: 2px solid var(--color-light-copy);
} */

.dark .content {
  border-top: 2px solid var(--color-grey-20);
}

.light .content {
  border-top: 2px solid var(--color-light-copy);
}

.wrapper {
  padding: 76px 20px;

  @media (--viewport-md) {
    display: flex;
    flex-basis: auto;
    padding: 110px 34px;
  }

  @media (--viewport-lg) {
    width: 66.66667%;
    flex-basis: auto;
    flex-direction: column;
    padding: 115px 0;
    margin: auto;
  }
}

.container,
.mediaWrapper {
  @media (--viewport-lg) {
    display: flex;
    width: 50%;
    flex-basis: auto;
  }
}

.container {
  font-family: var(--font-family-sans-serif-regular);
  font-size: rem(15px);
  line-height: 1.6;
}

.header.header {
  @media (--viewport-md-only) {
    width: 50%;
    flex-basis: auto;
    margin-right: 20px;
  }
}

.mediaWrapper {
  height: auto;

  img {
    width: 100%;
    height: 100%;
    flex: 1 0 auto;
    line-height: 0;
    object-fit: cover;
    transform: none;
  }

  picture {
    padding: 0;
    margin: 0;
    line-height: 0;
  }
}

.isFullWidthImage {
  height: 100%;

  @media (--viewport-lg) {
    position: absolute;
    z-index: var(--z-index-background-image);
    width: 100%;
  }
}

.hasFullWidthImage {
  z-index: var(--z-index-forground-content);
  height: 100%;

  @media (--viewport-lg) {
    margin: auto 60px auto calc(50% + 60px);
  }

  .reverse & {
    @media (--viewport-lg) {
      margin: auto calc(50% + 60px) auto 60px;
    }
  }
}

.description {
  padding-top: 20px;
  font-size: rem(15px);

  &:last-of-type {
    margin-bottom: 26px;
  }
}
