@import url(https://d8ejoa1fys2rk.cloudfront.net/bynder-embed/2.13/css/bynder-widgets.min.css);
/* @import './key-frame.css'; */
/* @import './font-face.css'; */
:root {
  /* Base colours */

  /* UI colours light theme */

  /* UI colours dark theme */

  /* Background colours */

  /* System colours */
}
/* Dark theme UI elements */
/* Light theme UI elements */
/* UI colours light theme */
/*
--color-light-copy: var(--color-primary);
--color-light-copy-alternate: var(--color-white-darker);
--color-light-label: var(--color-white-darker);
--color-light-link: var(--color-primary);
--color-light-link-hover: var(--color-white-darker);
--color-light-decoration: var(--color-white-darker);
--color-light-disabled: var(--color-grey-60);
*/
/* UI colours dark theme */
/*
--color-dark-copy: var(--color-secondary);
--color-dark-copy-alternate: var(--color-grey-63);
--color-dark-label: var(--color-grey-30);
--color-dark-link: var(--color-grey-20);
--color-dark-link-hover: var(--color-grey-10);
--color-dark-decoration: var(--color-grey-30);
--color-dark-disabled: var(--color-grey-60);
*/
.Icon-module_base__iPRbu {
  outline: none;
}
.Icon-module_base__iPRbu path {
    transition: fill 200ms cubic-bezier(0.645, 0.045, 0.355, 1);
  }
.Icon-module_base__iPRbu.Icon-module_dark__3KB8t {
    fill: #333;
  }
.Icon-module_base__iPRbu.Icon-module_light__15Pp3 {
    fill: #fffef0;
  }
.Icon-module_plusAndCloseWithCircle__Y4-yj {
  transition: all 200ms cubic-bezier(0.645, 0.045, 0.355, 1);
}
.Icon-module_plusAndCloseWithCircle__Y4-yj .Icon-module_circle__4IIx2 {
    fill: transparent;
    stroke-width: 3px;
    transition: fill 200ms cubic-bezier(0.645, 0.045, 0.355, 1);
  }
.Icon-module_plusAndCloseWithCircle__Y4-yj .Icon-module_circle__4IIx2.Icon-module_dark__3KB8t {
      stroke: #333;
    }
.Icon-module_plusAndCloseWithCircle__Y4-yj .Icon-module_circle__4IIx2.Icon-module_light__15Pp3 {
      stroke: #fffef0;
    }
.Icon-module_plusAndCloseWithCircle__Y4-yj .Icon-module_plus__2RJbo {
    opacity: 1;
    stroke-width: 2px;
    transition:
      fill 200ms cubic-bezier(0.645, 0.045, 0.355, 1),
      stroke 200ms cubic-bezier(0.645, 0.045, 0.355, 1),
      opacity 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
  }
.Icon-module_plusAndCloseWithCircle__Y4-yj .Icon-module_plus__2RJbo.Icon-module_dark__3KB8t {
      fill: #333;
      stroke: #333;
    }
.Icon-module_plusAndCloseWithCircle__Y4-yj .Icon-module_plus__2RJbo.Icon-module_light__15Pp3 {
      fill: #fffef0;
      stroke: #fffef0;
    }
.Icon-module_plusAndCloseWithCircle__Y4-yj .Icon-module_close__1Owsp {
    opacity: 0;
    stroke-width: 2px;
    transition: opacity 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
  }
.Icon-module_plusAndCloseWithCircle__Y4-yj .Icon-module_close__1Owsp.Icon-module_dark__3KB8t {
      fill: #333;
      stroke: #333;
    }
.Icon-module_plusAndCloseWithCircle__Y4-yj .Icon-module_close__1Owsp.Icon-module_light__15Pp3 {
      fill: #fffef0;
      stroke: #fffef0;
    }

/* @import './key-frame.css'; */
/* @import './font-face.css'; */
:root {
  /* Base colours */

  /* UI colours light theme */

  /* UI colours dark theme */

  /* Background colours */

  /* System colours */
}
/* Dark theme UI elements */
/* Light theme UI elements */
/* UI colours light theme */
/*
--color-light-copy: var(--color-primary);
--color-light-copy-alternate: var(--color-white-darker);
--color-light-label: var(--color-white-darker);
--color-light-link: var(--color-primary);
--color-light-link-hover: var(--color-white-darker);
--color-light-decoration: var(--color-white-darker);
--color-light-disabled: var(--color-grey-60);
*/
/* UI colours dark theme */
/*
--color-dark-copy: var(--color-secondary);
--color-dark-copy-alternate: var(--color-grey-63);
--color-dark-label: var(--color-grey-30);
--color-dark-link: var(--color-grey-20);
--color-dark-link-hover: var(--color-grey-10);
--color-dark-decoration: var(--color-grey-30);
--color-dark-disabled: var(--color-grey-60);
*/
.Accordion-module_base__3VTZg {
  font-family: 'Suisse Regular'
,
sans-serif;
}
.Accordion-module_item__ZGEfE {
  padding: 20px;
}
@media (min-width: 640px) {
.Accordion-module_item__ZGEfE {
    padding: 30px
}
  }
.Accordion-module_item__ZGEfE:not(:last-child) {
    border-bottom: 1px solid #bcbbb4;
  }
.Accordion-module_dark__2Oiu4 {
  color: #333
}
.Accordion-module_dark__2Oiu4 svg {
    fill: #333;
  }
.Accordion-module_light__2ieEh {
  color: #fffef0
}
.Accordion-module_light__2ieEh svg {
    fill: #fffef0;
  }
.Accordion-module_headingCopy__3ORa4 {
  /** @TODO replace with a heading font mixin */
  /* font-size: ; 1.45rem */
  font-size: 23px;
  line-height: 1.5;
}
@media (min-width: 640px) {
.Accordion-module_headingCopy__3ORa4 {
    /* font-size: 1.55rem; */
    font-size: 25px
}
  }
@media (min-width: 1025px) {
.Accordion-module_headingCopy__3ORa4 {
    /* font-size: 1.65rem; */
    font-size: 27px
}
  }
.Accordion-module_button__2Lf1- {
  display: flex;
  width: 100%;
  align-items: baseline;
  justify-content: space-between;
  cursor: pointer;
  outline: 0;
}
.Accordion-module_icon__2_wvH {
  min-width: 10%;
  margin-left: 20px;
  outline: 0;
  transition: -webkit-transform 500ms cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 500ms cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 500ms cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 500ms cubic-bezier(0.215, 0.61, 0.355, 1);
}
.Accordion-module_button__2Lf1-[aria-expanded='true'] .Accordion-module_icon__2_wvH,
  .Accordion-module_button__2Lf1-[aria-selected='true'] .Accordion-module_icon__2_wvH {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
.Accordion-module_collapse__16QRS {
  opacity: 0;
  transition:
    height 400ms cubic-bezier(0.215, 0.61, 0.355, 1),
    opacity 800ms cubic-bezier(0.215, 0.61, 0.355, 1);
}
.Accordion-module_panel__14OQe[hidden] {
    display: block;
  }
.Accordion-module_panel__14OQe[aria-hidden='false'] .Accordion-module_collapse__16QRS {
      opacity: 1;
    }
.Accordion-module_content__38Xon {
  padding-top: 20px;
  font-size: 17px;
  line-height: 1.6;
}
.Accordion-module_content__38Xon p:first-of-type {
      margin-top: 0;
    }
.Accordion-module_content__38Xon p:last-of-type {
      margin-bottom: 0;
    }
@media (min-width: 640px) {
.Accordion-module_content__38Xon {
    padding-top: 30px
}
  }

/* @import './key-frame.css'; */
/* @import './font-face.css'; */
:root {
  /* Base colours */

  /* UI colours light theme */

  /* UI colours dark theme */

  /* Background colours */

  /* System colours */
}
/* Dark theme UI elements */
/* Light theme UI elements */
/* UI colours light theme */
/*
--color-light-copy: var(--color-primary);
--color-light-copy-alternate: var(--color-white-darker);
--color-light-label: var(--color-white-darker);
--color-light-link: var(--color-primary);
--color-light-link-hover: var(--color-white-darker);
--color-light-decoration: var(--color-white-darker);
--color-light-disabled: var(--color-grey-60);
*/
/* UI colours dark theme */
/*
--color-dark-copy: var(--color-secondary);
--color-dark-copy-alternate: var(--color-grey-63);
--color-dark-label: var(--color-grey-30);
--color-dark-link: var(--color-grey-20);
--color-dark-link-hover: var(--color-grey-10);
--color-dark-decoration: var(--color-grey-30);
--color-dark-disabled: var(--color-grey-60);
*/
.Heading-module_base__Afh6b {
  padding: 0;
  margin: 20px 0;
  font-family: 'Suisse Regular'
,
sans-serif;
  font-weight: 400;
  line-height: 1.2;
  -webkit-text-size-adjust: 100%;
     -moz-text-size-adjust: 100%;
      -ms-text-size-adjust: 100%;
          text-size-adjust: 100%;
}
.Heading-module_dark__3xDuY {
  color: #333;
}
.Heading-module_light__O5PRI {
  color: #fffef0;
}
.Heading-module_noTopMargin__2Zfkb {
  margin-top: 0;
}
.Heading-module_noMargin__25BGU {
  margin: 0;
}
.Heading-module_serifFont__pIB5u {
  font-family: 'Zapf Humanist'
,
serif;
  font-weight: 300;
}
.Heading-module_mediumWeightFont__3u5dB {
  font-family: 'Suisse Medium'
,
sans-serif;
}
.Heading-module_xXSmall__1VQgr {
  font-size: 14px;
  line-height: 1.7;
}
.Heading-module_xSmall__3AWRl {
  font-size: 15px;
  line-height: 1.6;
}
@media (min-width: 640px) {
.Heading-module_xSmall__3AWRl {
    font-size: 17px
}
  }
.Heading-module_small__3Aeb8 {
  font-size: 17px;
  line-height: 1.5;
}
@media (min-width: 640px) {
.Heading-module_small__3Aeb8 {
    font-size: 19px
}
  }
.Heading-module_medium__5lYPh {
  font-size: 20px;
  line-height: 1.4;
}
@media (min-width: 640px) {
.Heading-module_medium__5lYPh {
    font-size: 22px
}
  }
.Heading-module_large__2BJbn {
  font-size: 23px;
  line-height: 1.4;
}
@media (min-width: 640px) {
.Heading-module_large__2BJbn {
    font-size: 25px
}
  }
@media (min-width: 1025px) {
.Heading-module_large__2BJbn {
    font-size: 27px
}
  }
.Heading-module_xLarge__2F_YR {
  font-size: 26px;
  line-height: 1.3;
}
@media (min-width: 640px) {
.Heading-module_xLarge__2F_YR {
    font-size: 28px
}
  }
@media (min-width: 1025px) {
.Heading-module_xLarge__2F_YR {
    font-size: 30px
}
  }

:root {
  /* Base colours */

  /* UI colours light theme */

  /* UI colours dark theme */

  /* Background colours */

  /* System colours */
}
.SectionHeading-module_base__MQ5b- {
  position: relative;
}
.SectionHeading-module_heading__qFPqU {
  margin: 0 0 20px;
}

/* @import './key-frame.css'; */
/* @import './font-face.css'; */
:root {
  /* Base colours */

  /* UI colours light theme */

  /* UI colours dark theme */

  /* Background colours */

  /* System colours */
}
/* Dark theme UI elements */
/* Light theme UI elements */
/* UI colours light theme */
/*
--color-light-copy: var(--color-primary);
--color-light-copy-alternate: var(--color-white-darker);
--color-light-label: var(--color-white-darker);
--color-light-link: var(--color-primary);
--color-light-link-hover: var(--color-white-darker);
--color-light-decoration: var(--color-white-darker);
--color-light-disabled: var(--color-grey-60);
*/
/* UI colours dark theme */
/*
--color-dark-copy: var(--color-secondary);
--color-dark-copy-alternate: var(--color-grey-63);
--color-dark-label: var(--color-grey-30);
--color-dark-link: var(--color-grey-20);
--color-dark-link-hover: var(--color-grey-10);
--color-dark-decoration: var(--color-grey-30);
--color-dark-disabled: var(--color-grey-60);
*/
.Hyperlink-module_base__uoaww {
  position: relative;
  font-family: 'Suisse Regular'
,
sans-serif;
  font-size: inherit;
  font-weight: 400;
  outline: none;
  text-decoration: none;
}
.Hyperlink-module_hasIcon__1893y {
  padding-right: 26px;
}
.Hyperlink-module_icon__1hgGy {
  position: absolute;
  top: calc(50% - 8px);
  right: 0;
  display: inline-block;
}
.Hyperlink-module_icon__1hgGy svg {
    width: 15px;
    max-height: 15px;
  }
.Hyperlink-module_icon__1hgGy svg path {
      transition: fill 150ms cubic-bezier(0.645, 0.045, 0.355, 1);
    }
.Hyperlink-module_blockStyle__3pIk6 {
  display: inline-block;
  min-width: 210px;
  max-width: 400px;
  padding: 20px;
  border-width: 1px;
  border-style: solid;
  transition: all 200ms cubic-bezier(0.645, 0.045, 0.355, 1);
}
.Hyperlink-module_blockStyle__3pIk6.Hyperlink-module_hasIcon__1893y {
    padding-right: 55px;
  }
.Hyperlink-module_blockStyle__3pIk6 .Hyperlink-module_icon__1hgGy {
    position: absolute;
    top: calc(50% - 8px);
    right: 23px;
  }
.Hyperlink-module_dark__3QzFC {
  color: #4a4a4a
}
.Hyperlink-module_dark__3QzFC:hover,
  .Hyperlink-module_dark__3QzFC:focus {
    color: #252525;
  }
.Hyperlink-module_dark__3QzFC .Hyperlink-module_icon__1hgGy svg path {
        fill: #333;
      }
.Hyperlink-module_dark__3QzFC.Hyperlink-module_blockStyle__3pIk6 .Hyperlink-module_icon__1hgGy svg path {
          fill: #333;
        }
.Hyperlink-module_dark__3QzFC.Hyperlink-module_blockStyle__3pIk6:hover,
    .Hyperlink-module_dark__3QzFC.Hyperlink-module_blockStyle__3pIk6:focus {
      border-color: #333;
      background-color: #333;
      color: #f6f5e8;
    }
.Hyperlink-module_dark__3QzFC.Hyperlink-module_blockStyle__3pIk6:hover .Hyperlink-module_icon__1hgGy svg path, .Hyperlink-module_dark__3QzFC.Hyperlink-module_blockStyle__3pIk6:focus .Hyperlink-module_icon__1hgGy svg path {
            fill: #f6f5e8;
          }
.Hyperlink-module_light__6lcpR.Hyperlink-module_light__6lcpR {
  color: #fffef0
}
.Hyperlink-module_light__6lcpR.Hyperlink-module_light__6lcpR:hover,
  .Hyperlink-module_light__6lcpR.Hyperlink-module_light__6lcpR:focus {
    color: #f6f5e8;
  }
.Hyperlink-module_light__6lcpR.Hyperlink-module_light__6lcpR .Hyperlink-module_icon__1hgGy.Hyperlink-module_icon__1hgGy svg path {
        fill: #fffef2;
      }
.Hyperlink-module_light__6lcpR.Hyperlink-module_light__6lcpR.Hyperlink-module_blockStyle__3pIk6 {
    border: 1px solid #fffef2;
  }
.Hyperlink-module_light__6lcpR.Hyperlink-module_light__6lcpR.Hyperlink-module_blockStyle__3pIk6 .Hyperlink-module_icon__1hgGy svg path {
          fill: #fffef2;
        }
.Hyperlink-module_light__6lcpR.Hyperlink-module_light__6lcpR.Hyperlink-module_blockStyle__3pIk6:focus,
    .Hyperlink-module_light__6lcpR.Hyperlink-module_light__6lcpR.Hyperlink-module_blockStyle__3pIk6:hover {
      background-color: #fffef2;
      color: #333;
    }
.Hyperlink-module_light__6lcpR.Hyperlink-module_light__6lcpR.Hyperlink-module_blockStyle__3pIk6:focus .Hyperlink-module_icon__1hgGy svg path, .Hyperlink-module_light__6lcpR.Hyperlink-module_light__6lcpR.Hyperlink-module_blockStyle__3pIk6:hover .Hyperlink-module_icon__1hgGy svg path {
            fill: #333;
          }

/* @import './key-frame.css'; */
/* @import './font-face.css'; */
:root {
  /* Base colours */

  /* UI colours light theme */

  /* UI colours dark theme */

  /* Background colours */

  /* System colours */
}
/* Dark theme UI elements */
/* Light theme UI elements */
/* UI colours light theme */
/*
--color-light-copy: var(--color-primary);
--color-light-copy-alternate: var(--color-white-darker);
--color-light-label: var(--color-white-darker);
--color-light-link: var(--color-primary);
--color-light-link-hover: var(--color-white-darker);
--color-light-decoration: var(--color-white-darker);
--color-light-disabled: var(--color-grey-60);
*/
/* UI colours dark theme */
/*
--color-dark-copy: var(--color-secondary);
--color-dark-copy-alternate: var(--color-grey-63);
--color-dark-label: var(--color-grey-30);
--color-dark-link: var(--color-grey-20);
--color-dark-link-hover: var(--color-grey-10);
--color-dark-decoration: var(--color-grey-30);
--color-dark-disabled: var(--color-grey-60);
*/
.BodyCopy-module_base__1ZoAL {
  font-family: 'Suisse Regular'
,
sans-serif;
}
.BodyCopy-module_base__1ZoAL:not(:last-child) {
    margin-bottom: 16px;
  }
@media (min-width: 640px) {
    .BodyCopy-module_base__1ZoAL:not(:last-child) {
      margin-bottom: 34px;
    }
  }
@media (--viewport-ld) {
    .BodyCopy-module_base__1ZoAL:not(:last-child) {
      margin-bottom: var(--layout-ld-spacing);
    }
  }
.BodyCopy-module_ctaWrapper__3vF4R {
  margin-top: calc(16px / 2);
}
@media (min-width: 640px) {
.BodyCopy-module_ctaWrapper__3vF4R {
    margin-top: calc(34px / 2)
}
  }
@media (min-width: 1025px) {
.BodyCopy-module_ctaWrapper__3vF4R {
    margin-top: calc(34px / 2)
}
  }
.BodyCopy-module_ctaWrapper__3vF4R a {
    display: inline-block;
    margin-top: calc(16px / 2);
  }
@media (min-width: 640px) {
.BodyCopy-module_ctaWrapper__3vF4R a {
      margin-top: calc(34px / 2)
  }
    }
@media (min-width: 1025px) {
.BodyCopy-module_ctaWrapper__3vF4R a {
      margin-top: calc(34px / 2)
  }
    }
.BodyCopy-module_copy__2K-qP,
  .BodyCopy-module_copy__2K-qP p {
    margin: 10px 0;
    font-size: 17px;
    line-height: 1.7;
  }
.BodyCopy-module_copy__2K-qP:first-of-type, .BodyCopy-module_copy__2K-qP p:first-of-type {
      margin-top: 0;
    }
.BodyCopy-module_copy__2K-qP:last-of-type, .BodyCopy-module_copy__2K-qP p:last-of-type {
      margin-bottom: 0;
    }
.BodyCopy-module_copy__2K-qP a, .BodyCopy-module_copy__2K-qP p a {
      font-weight: 400;
    }
.BodyCopy-module_dark__1kIgR.BodyCopy-module_copy__2K-qP,
    .BodyCopy-module_dark__1kIgR.BodyCopy-module_copy__2K-qP p {
  color: #333;
    }
.BodyCopy-module_dark__1kIgR.BodyCopy-module_copy__2K-qP a {
      /* @extend %font-theme-dark-link; */
      color: #4a4a4a
    }
.BodyCopy-module_dark__1kIgR.BodyCopy-module_copy__2K-qP a:hover,
      .BodyCopy-module_dark__1kIgR.BodyCopy-module_copy__2K-qP a:focus {
        color: #252525;
      }
.BodyCopy-module_light__1BpzV.BodyCopy-module_light__1BpzV.BodyCopy-module_copy__2K-qP,
    .BodyCopy-module_light__1BpzV.BodyCopy-module_light__1BpzV.BodyCopy-module_copy__2K-qP p {
  color: #fffef0;
    }
.BodyCopy-module_light__1BpzV.BodyCopy-module_light__1BpzV.BodyCopy-module_copy__2K-qP a {
      /* @extend %font-theme-light-link; */
      color: #fffef0
    }
.BodyCopy-module_light__1BpzV.BodyCopy-module_light__1BpzV.BodyCopy-module_copy__2K-qP a:hover,
      .BodyCopy-module_light__1BpzV.BodyCopy-module_light__1BpzV.BodyCopy-module_copy__2K-qP a:focus {
        color: #f6f5e8;
      }
/* .HeroWithContent {
  .heading,
  .subHeading {
    margin: 0;
  }

  .subHeading {
    padding-top: 5px;
  }

  .copy.copy {
    margin: 30px 0 0;

    a {
      width: 100%;
      margin-top: 30px;

      @media (--viewport-md) {
        width: auto;
      }
    }
  }
}

.TwoColumn {
  .heading {
    font-family: var(--font-family-zapf-serif);
  }
}

.HalfWidthFullBleedHero {
  .eyebrow {
    padding: 0 0 19px;

    @media (--viewport-lg) {
      padding: 0 0 14px;
      margin-top: 60px;
    }

    @media (--viewport-xl) {
      padding: 0 0 19px;
    }
  }

  .heading {
    padding: 0 0 17px 0;
    border-bottom: 2px solid var(--color-grey-10);
    font-family: var(--font-family-zapf-serif);

    @media (--viewport-md) {
      padding: 0 0 12px;
    }

    @media (--viewport-xl) {
      padding: 0 0 17px;
    }
  }
} */

/* @import './key-frame.css'; */
/* @import './font-face.css'; */
:root {
  /* Base colours */

  /* UI colours light theme */

  /* UI colours dark theme */

  /* Background colours */

  /* System colours */
}
/* Dark theme UI elements */
/* Light theme UI elements */
/* UI colours light theme */
/*
--color-light-copy: var(--color-primary);
--color-light-copy-alternate: var(--color-white-darker);
--color-light-label: var(--color-white-darker);
--color-light-link: var(--color-primary);
--color-light-link-hover: var(--color-white-darker);
--color-light-decoration: var(--color-white-darker);
--color-light-disabled: var(--color-grey-60);
*/
/* UI colours dark theme */
/*
--color-dark-copy: var(--color-secondary);
--color-dark-copy-alternate: var(--color-grey-63);
--color-dark-label: var(--color-grey-30);
--color-dark-link: var(--color-grey-20);
--color-dark-link-hover: var(--color-grey-10);
--color-dark-decoration: var(--color-grey-30);
--color-dark-disabled: var(--color-grey-60);
*/
.Breadcrumbs-module_base__1qjpj {
  margin: calc(16px / 2) 0;
}
@media (min-width: 640px) {
.Breadcrumbs-module_base__1qjpj {
    margin: calc(34px / 2) 0
}
  }
@media (--viewport-ld) {
.Breadcrumbs-module_base__1qjpj {
    margin: calc(var(--layout-ld-spacing) / 2) 0
}
  }
.Breadcrumbs-module_list__eE_Me {
  padding: 0;
  margin: 0;
  list-style: 0;
}
.Breadcrumbs-module_item__1Kvmk {
  display: inline-block;
  padding: 0;
}
.Breadcrumbs-module_item__1Kvmk:not(:first-child)::before {
    display: inline-block;
    padding: 0 10px;
    content: '\2022';
    font-size: 20px;
    line-height: 1;
    -webkit-transform: translateY(3px);
            transform: translateY(3px);
  }
.Breadcrumbs-module_link__2UQyz {
  position: relative;
  display: inline-block;
  color: #333;
  font-size: 14px;
}
.Breadcrumbs-module_link__2UQyz::after {
    display: block;
    border-bottom: solid 1px #333;
    margin-top: -3px;
    margin-top: 0;
    margin-bottom: -10px;
    content: '';
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
    -webkit-transform-origin: left;
            transform-origin: left;
    transition: -webkit-transform 250ms cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: transform 250ms cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: transform 250ms cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 250ms cubic-bezier(0.215, 0.61, 0.355, 1);
  }
.Breadcrumbs-module_link__2UQyz:hover::after,
  .Breadcrumbs-module_link__2UQyz:focus::after {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
  }

/* @import './key-frame.css'; */
/* @import './font-face.css'; */
:root {
  /* Base colours */

  /* UI colours light theme */

  /* UI colours dark theme */

  /* Background colours */

  /* System colours */
}
/* Dark theme UI elements */
/* Light theme UI elements */
/* UI colours light theme */
/*
--color-light-copy: var(--color-primary);
--color-light-copy-alternate: var(--color-white-darker);
--color-light-label: var(--color-white-darker);
--color-light-link: var(--color-primary);
--color-light-link-hover: var(--color-white-darker);
--color-light-decoration: var(--color-white-darker);
--color-light-disabled: var(--color-grey-60);
*/
/* UI colours dark theme */
/*
--color-dark-copy: var(--color-secondary);
--color-dark-copy-alternate: var(--color-grey-63);
--color-dark-label: var(--color-grey-30);
--color-dark-link: var(--color-grey-20);
--color-dark-link-hover: var(--color-grey-10);
--color-dark-decoration: var(--color-grey-30);
--color-dark-disabled: var(--color-grey-60);
*/
.Button-module_base__3aZlL {
  position: relative;
  padding: 0;
  border: 0;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: transparent;
  cursor: pointer;
  font-family: 'Suisse Regular'
,
sans-serif;
  font-size: 15px;
  font-weight: 400;
  outline: none;
  text-decoration: none;
}
.Button-module_base__3aZlL > * {
    pointer-events: none;
  }
.Button-module_blockStyle__3UjpG {
  display: inline-block;
  min-width: 210px;
  max-width: 400px;
  padding: 20px;
  border-width: 1px;
  border-style: solid;
  transition: all 200ms cubic-bezier(0.645, 0.045, 0.355, 1);
}
.Button-module_dark__39IaJ {
  color: #4a4a4a
}
.Button-module_dark__39IaJ:hover,
  .Button-module_dark__39IaJ:focus {
    color: #252525;
  }
.Button-module_dark__39IaJ.Button-module_blockStyle__3UjpG {
    border-color: #333
  }
.Button-module_dark__39IaJ.Button-module_blockStyle__3UjpG:hover,
    .Button-module_dark__39IaJ.Button-module_blockStyle__3UjpG:focus {
      background-color: #333;
      color: #f6f5e8;
    }
.Button-module_dark__39IaJ.Button-module_blockStyle__3UjpG.Button-module_alternate__feDv- {
      border-color: #333;
      background-color: #333;
      color: #f6f5e8
    }
.Button-module_dark__39IaJ.Button-module_blockStyle__3UjpG.Button-module_alternate__feDv-:hover,
      .Button-module_dark__39IaJ.Button-module_blockStyle__3UjpG.Button-module_alternate__feDv-:focus {
        border-color: #000;
        background-color: #000;
      }
.Button-module_light__3pPaS.Button-module_light__3pPaS {
  color: #fffef0
}
.Button-module_light__3pPaS.Button-module_light__3pPaS:hover,
  .Button-module_light__3pPaS.Button-module_light__3pPaS:focus {
    color: #f6f5e8;
  }
.Button-module_light__3pPaS.Button-module_light__3pPaS.Button-module_blockStyle__3UjpG {
    border-color: #fffef2
  }
.Button-module_light__3pPaS.Button-module_light__3pPaS.Button-module_blockStyle__3UjpG:focus,
    .Button-module_light__3pPaS.Button-module_light__3pPaS.Button-module_blockStyle__3UjpG:hover {
      background-color: #fffef2;
      color: #333;
    }
.Button-module_light__3pPaS.Button-module_light__3pPaS.Button-module_alternate__feDv- {
    border-color: #f6f5e8;
    background-color: #f6f5e8;
    color: #333
  }
.Button-module_light__3pPaS.Button-module_light__3pPaS.Button-module_alternate__feDv-:hover,
    .Button-module_light__3pPaS.Button-module_light__3pPaS.Button-module_alternate__feDv-:focus {
      border-color: #fffef2;
      background-color: #fffef2;
    }
.Button-module_disabled__2hS0q {
  background-color: transparent;
  cursor: default;
}
.Button-module_disabled__2hS0q.Button-module_dark__39IaJ {
    color: #bcbbb4;
  }
.Button-module_disabled__2hS0q.Button-module_dark__39IaJ.Button-module_blockStyle__3UjpG {
      border-color: #bcbbb4;
    }
.Button-module_disabled__2hS0q.Button-module_dark__39IaJ:hover,
    .Button-module_disabled__2hS0q.Button-module_dark__39IaJ:focus {
      background-color: transparent;
      color: #bcbbb4;
    }
.Button-module_disabled__2hS0q.Button-module_dark__39IaJ:hover.Button-module_blockStyle__3UjpG, .Button-module_disabled__2hS0q.Button-module_dark__39IaJ:focus.Button-module_blockStyle__3UjpG {
        border-color: #bcbbb4;
      }
.Button-module_disabled__2hS0q.Button-module_light__3pPaS.Button-module_light__3pPaS {
    color: #6b6b6b;
  }
.Button-module_disabled__2hS0q.Button-module_light__3pPaS.Button-module_light__3pPaS.Button-module_blockStyle__3UjpG {
      border-color: #6b6b6b;
    }
.Button-module_disabled__2hS0q.Button-module_light__3pPaS.Button-module_light__3pPaS:hover,
    .Button-module_disabled__2hS0q.Button-module_light__3pPaS.Button-module_light__3pPaS:focus {
      background-color: transparent;
      color: #6b6b6b;
    }
.Button-module_disabled__2hS0q.Button-module_light__3pPaS.Button-module_light__3pPaS:hover.Button-module_blockStyle__3UjpG, .Button-module_disabled__2hS0q.Button-module_light__3pPaS.Button-module_light__3pPaS:focus.Button-module_blockStyle__3UjpG {
        border-color: #6b6b6b;
      }
:root {
  /* Base colours */

  /* UI colours light theme */

  /* UI colours dark theme */

  /* Background colours */

  /* System colours */
}
.BynderWidget-module_heading__3TI6o {
  position: absolute;
  z-index: 9;
  margin-top: 16px;
  margin-left: 18px;
  font-size: 15px;
  font-weight: bold;
}
.BynderWidget-module_base__2Wf5w .bynder-widget-media-list {
      border: 1px solid #ddd !important;
      margin-bottom: 50px;
      border-radius: 0 !important;
    }
.BynderWidget-module_base__2Wf5w .bynder-widget-media-list .bynder-widget-header,
    .BynderWidget-module_base__2Wf5w .bynder-widget-media-list .bynder-widget-content {
      border: none !important;
      background: #fffef2 !important;
      box-shadow: none !important;
    }
.BynderWidget-module_base__2Wf5w a.bynder-widget-btn {
      border: 1px solid #333 !important;
      background: #333 !important;
      border-radius: 0 !important;
      box-shadow: 0 0 #333 !important;
    }
.BynderWidget-module_base__2Wf5w a.bynder-widget-btn:hover {
      background: #333 !important;
    }
.BynderWidget-module_base__2Wf5w div.media-item a.bynder-widget-media-link,
    .BynderWidget-module_base__2Wf5w ul.bynder-widget-media-items li a.bynder-widget-media-link {
      width: 80% !important;
      border: 1px solid #ddd !important;
      background: #fffef2 !important;
      box-shadow: 0 0 transparent !important;
    }
.BynderWidget-module_base__2Wf5w ul.bynder-widget-media-items li a.bynder-widget-media-link {
      border-radius: 0 !important;
    }
.BynderWidget-module_base__2Wf5w div.media-item:hover a.bynder-widget-media-link,
    .BynderWidget-module_base__2Wf5w ul.bynder-widget-media-items li:hover a.bynder-widget-media-link {
      padding: 10px !important;
      border: 2px solid #333 !important;
      box-shadow: 0 0 transparent !important;
    }
.BynderWidget-module_base__2Wf5w .bynder-widget-media-list .bynder-widget-header a.bynder-widget-link {
      visibility: hidden !important;
    }
.BynderWidget-module_base__2Wf5w div.media-item a p,
    .BynderWidget-module_base__2Wf5w ul.bynder-widget-media-items li a p {
      width: 100% !important;
    }
.BynderWidget-module_base__2Wf5w ul.bynder-widget-media-items li a .bynder-widget-mediaholder {
      width: 100% !important;
      background: transparent !important;
    }
.BynderWidget-module_base__2Wf5w .bynder-widget-header a.bynder-widget-btn {
      right: 18px !important;
    }
.BynderWidget-module_base__2Wf5w ul.bynder-widget-media-items li a.bynder-widget-btn {
      top: 0 !important;
      right: 0 !important;
      bottom: 0;
      left: 0;
      width: 70% !important;
      margin: auto !important;
    }
.BynderWidget-module_base__2Wf5w ul.bynder-widget-media-items li a.bynder-widget-btn:hover {
      width: 70% !important;
    }
.BynderWidget-module_base__2Wf5w .bynder-widget-media-list .bynder-widget-content {
      height: auto !important;
    }

/* @import './key-frame.css'; */
/* @import './font-face.css'; */
:root {
  /* Base colours */

  /* UI colours light theme */

  /* UI colours dark theme */

  /* Background colours */

  /* System colours */
}
/* Dark theme UI elements */
/* Light theme UI elements */
/* UI colours light theme */
/*
--color-light-copy: var(--color-primary);
--color-light-copy-alternate: var(--color-white-darker);
--color-light-label: var(--color-white-darker);
--color-light-link: var(--color-primary);
--color-light-link-hover: var(--color-white-darker);
--color-light-decoration: var(--color-white-darker);
--color-light-disabled: var(--color-grey-60);
*/
/* UI colours dark theme */
/*
--color-dark-copy: var(--color-secondary);
--color-dark-copy-alternate: var(--color-grey-63);
--color-dark-label: var(--color-grey-30);
--color-dark-link: var(--color-grey-20);
--color-dark-link-hover: var(--color-grey-10);
--color-dark-decoration: var(--color-grey-30);
--color-dark-disabled: var(--color-grey-60);
*/
.Paragraph-module_paragraph__3AXc0 {
  margin: 18px 0;
  font-size: 15px;
  line-height: 1.6;
}
.Paragraph-module_paragraph__3AXc0:first-of-type {
    margin-top: 0;
  }
.Paragraph-module_paragraph__3AXc0:last-of-type {
    margin-bottom: 0;
  }
.Paragraph-module_base__2wcSK {
  margin: 18px 0;
  font-size: 15px;
  line-height: 1.6;
}
.Paragraph-module_base__2wcSK:first-of-type {
    margin-top: 0;
  }
.Paragraph-module_base__2wcSK:last-of-type {
    margin-bottom: 0;
  }
.Paragraph-module_base__2wcSK {

  font-family: 'Suisse Regular'
,
sans-serif;
}
.Paragraph-module_dark__GxvVO,
  .Paragraph-module_dark__GxvVO p {
  color: #333;
  }
.Paragraph-module_light__yJGX7,
  .Paragraph-module_light__yJGX7 p {
  color: #fffef0;
  }
.Paragraph-module_set__wOcFC p {
  margin: 18px 0;
  font-size: 15px;
  line-height: 1.6;
  }
.Paragraph-module_set__wOcFC p:first-of-type {
    margin-top: 0;
  }
.Paragraph-module_set__wOcFC p:last-of-type {
    margin-bottom: 0;
  }

/* @import './key-frame.css'; */
/* @import './font-face.css'; */
:root {
  /* Base colours */

  /* UI colours light theme */

  /* UI colours dark theme */

  /* Background colours */

  /* System colours */
}
/* Dark theme UI elements */
/* Light theme UI elements */
/* UI colours light theme */
/*
--color-light-copy: var(--color-primary);
--color-light-copy-alternate: var(--color-white-darker);
--color-light-label: var(--color-white-darker);
--color-light-link: var(--color-primary);
--color-light-link-hover: var(--color-white-darker);
--color-light-decoration: var(--color-white-darker);
--color-light-disabled: var(--color-grey-60);
*/
/* UI colours dark theme */
/*
--color-dark-copy: var(--color-secondary);
--color-dark-copy-alternate: var(--color-grey-63);
--color-dark-label: var(--color-grey-30);
--color-dark-link: var(--color-grey-20);
--color-dark-link-hover: var(--color-grey-10);
--color-dark-decoration: var(--color-grey-30);
--color-dark-disabled: var(--color-grey-60);
*/
.CarouselIntroduction-module_base__2sFJp {
  position: relative;
  display: flex;
  height: 100%;
  flex-direction: column;
  font-size: 15px;
}
@media (min-width: 640px) and (max-width: 1024px) {
.CarouselIntroduction-module_base__2sFJp {
    display: flex
}
  }
.CarouselIntroduction-module_heading__tajC7 {
  margin-top: 0;
  margin-bottom: 0;
}
@media (min-width: 640px) and (max-width: 1024px) {
.CarouselIntroduction-module_heading__tajC7 {
    position: absolute;
    left: 0;
    width: 50%;
    width: calc(50% - 34px);
    padding-right: 34px
}
  }
.CarouselIntroduction-module_description__XxH4y {
  max-width: 85%;
}
@media (min-width: 640px) and (max-width: 1024px) {
.CarouselIntroduction-module_description__XxH4y {
    width: 50%;
    max-width: 100%;
    margin-left: 50%
}
  }
.CarouselIntroduction-module_hasFooter__27iRg.CarouselIntroduction-module_hasFooter__27iRg {
  margin-bottom: 20px;
}
.CarouselIntroduction-module_ctaWrapper__3dGj2 {
  margin-top: auto;
}
@media (min-width: 640px) and (max-width: 1024px) {
.CarouselIntroduction-module_ctaWrapper__3dGj2 {
    width: 50%;
    max-width: 100%;
    margin-left: 50%
}
  }

/* @import './key-frame.css'; */
/* @import './font-face.css'; */
:root {
  /* Base colours */

  /* UI colours light theme */

  /* UI colours dark theme */

  /* Background colours */

  /* System colours */
}
/* Dark theme UI elements */
/* Light theme UI elements */
/* UI colours light theme */
/*
--color-light-copy: var(--color-primary);
--color-light-copy-alternate: var(--color-white-darker);
--color-light-label: var(--color-white-darker);
--color-light-link: var(--color-primary);
--color-light-link-hover: var(--color-white-darker);
--color-light-decoration: var(--color-white-darker);
--color-light-disabled: var(--color-grey-60);
*/
/* UI colours dark theme */
/*
--color-dark-copy: var(--color-secondary);
--color-dark-copy-alternate: var(--color-grey-63);
--color-dark-label: var(--color-grey-30);
--color-dark-link: var(--color-grey-20);
--color-dark-link-hover: var(--color-grey-10);
--color-dark-decoration: var(--color-grey-30);
--color-dark-disabled: var(--color-grey-60);
*/
.NextButton-module_base__3zqIF {
  position: absolute;
  z-index: 60;
  top: calc(50% - 80px);
  right: 0;
  display: flex;
  width: 60px;
  height: 60px;
  align-items: center;
  justify-content: center;
  background-color: #333;
  pointer-events: auto;
  transition: all 500ms cubic-bezier(0.215, 0.61, 0.355, 1);
}
@media (min-width: 1025px) {
.NextButton-module_base__3zqIF {
    width: 80px;
    height: 80px;
    -webkit-transform: translate3d(80px, 0, 0);
            transform: translate3d(80px, 0, 0)
}
  }
.NextButton-module_base__3zqIF svg {
    fill: #fffef2;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
  }
.NextButton-module_hidden__3F3MM {
  pointer-events: none;
  -webkit-transform: translate3d(80px, 0, 0) !important;
          transform: translate3d(80px, 0, 0) !important;
}
.slick-slider:hover .NextButton-module_base__3zqIF {
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
      }

/* @import './key-frame.css'; */
/* @import './font-face.css'; */
:root {
  /* Base colours */

  /* UI colours light theme */

  /* UI colours dark theme */

  /* Background colours */

  /* System colours */
}
/* Dark theme UI elements */
/* Light theme UI elements */
/* UI colours light theme */
/*
--color-light-copy: var(--color-primary);
--color-light-copy-alternate: var(--color-white-darker);
--color-light-label: var(--color-white-darker);
--color-light-link: var(--color-primary);
--color-light-link-hover: var(--color-white-darker);
--color-light-decoration: var(--color-white-darker);
--color-light-disabled: var(--color-grey-60);
*/
/* UI colours dark theme */
/*
--color-dark-copy: var(--color-secondary);
--color-dark-copy-alternate: var(--color-grey-63);
--color-dark-label: var(--color-grey-30);
--color-dark-link: var(--color-grey-20);
--color-dark-link-hover: var(--color-grey-10);
--color-dark-decoration: var(--color-grey-30);
--color-dark-disabled: var(--color-grey-60);
*/
.Pagination-module_base___SWdb {
  position: relative;
}
.Pagination-module_list__2Oj5X {
  position: relative;
  display: flex;
  height: 2px;
  padding: 0;
  margin: 0 20px 40px;
  background-color: #bcbbb4;
  list-style-type: none;
}
@media (min-width: 640px) {
.Pagination-module_list__2Oj5X {
    margin: 0 40px 40px
}
  }
@media (min-width: 1025px) {
.Pagination-module_list__2Oj5X {
    margin: 0 80px 40px
}
  }
.Pagination-module_list__2Oj5X .slick-active {
      background-color: #333;
    }
.Pagination-module_list__2Oj5X li {
    flex-grow: 1;
    margin: 0;
    transition: background-color 700ms cubic-bezier(0.215, 0.61, 0.355, 1);
  }
.Pagination-module_bar__31cQF {
  position: absolute;
  height: 2px;
  padding: 0;
  margin: 0;
  background-color: #333;
  transition: left 1000ms cubic-bezier(0.215, 0.61, 0.355, 1);
}

/* @import './key-frame.css'; */
/* @import './font-face.css'; */
:root {
  /* Base colours */

  /* UI colours light theme */

  /* UI colours dark theme */

  /* Background colours */

  /* System colours */
}
/* Dark theme UI elements */
/* Light theme UI elements */
/* UI colours light theme */
/*
--color-light-copy: var(--color-primary);
--color-light-copy-alternate: var(--color-white-darker);
--color-light-label: var(--color-white-darker);
--color-light-link: var(--color-primary);
--color-light-link-hover: var(--color-white-darker);
--color-light-decoration: var(--color-white-darker);
--color-light-disabled: var(--color-grey-60);
*/
/* UI colours dark theme */
/*
--color-dark-copy: var(--color-secondary);
--color-dark-copy-alternate: var(--color-grey-63);
--color-dark-label: var(--color-grey-30);
--color-dark-link: var(--color-grey-20);
--color-dark-link-hover: var(--color-grey-10);
--color-dark-decoration: var(--color-grey-30);
--color-dark-disabled: var(--color-grey-60);
*/
.PreviousButton-module_base__1J-Kp {
  position: absolute;
  z-index: 60;
  top: calc(50% - 80px);
  left: 0;
  display: flex;
  width: 60px;
  height: 60px;
  align-items: center;
  justify-content: center;
  background-color: #333;
  pointer-events: auto;
  transition: -webkit-transform 500ms cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 500ms cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 500ms cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 500ms cubic-bezier(0.215, 0.61, 0.355, 1);
}
@media (min-width: 1025px) {
.PreviousButton-module_base__1J-Kp {
    width: 80px;
    height: 80px;
    -webkit-transform: translate3d(-80px, 0, 0);
            transform: translate3d(-80px, 0, 0)
}
  }
.PreviousButton-module_base__1J-Kp svg {
    fill: #fffef2;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }
.PreviousButton-module_hidden__2FSw5 {
  pointer-events: none;
  -webkit-transform: translate3d(-80px, 0, 0) !important;
          transform: translate3d(-80px, 0, 0) !important;
}
.slick-slider:hover .PreviousButton-module_base__1J-Kp {
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
      }

/* @import './key-frame.css'; */
/* @import './font-face.css'; */
:root {
  /* Base colours */

  /* UI colours light theme */

  /* UI colours dark theme */

  /* Background colours */

  /* System colours */
}
/* Dark theme UI elements */
/* Light theme UI elements */
/* UI colours light theme */
/*
--color-light-copy: var(--color-primary);
--color-light-copy-alternate: var(--color-white-darker);
--color-light-label: var(--color-white-darker);
--color-light-link: var(--color-primary);
--color-light-link-hover: var(--color-white-darker);
--color-light-decoration: var(--color-white-darker);
--color-light-disabled: var(--color-grey-60);
*/
/* UI colours dark theme */
/*
--color-dark-copy: var(--color-secondary);
--color-dark-copy-alternate: var(--color-grey-63);
--color-dark-label: var(--color-grey-30);
--color-dark-link: var(--color-grey-20);
--color-dark-link-hover: var(--color-grey-10);
--color-dark-decoration: var(--color-grey-30);
--color-dark-disabled: var(--color-grey-60);
*/
.Image-module_base__18W0_ {
  display: inline-block;
}
.Image-module_picture__312I- {
  width: 100%;
  height: auto;
  padding: 0;
  margin: 0;
  text-align: center;
}
.Image-module_picture__312I- img {
    width: 100%;
    height: auto;
  }
.Image-module_isRightAligned__1le6V {
  padding:
    16px 0 16px
    16px;
}
.Image-module_fullBleedImage__1o9hJ {
  position: relative;
  width: 100%;
  padding: 0;
}
.Image-module_fullBleedImage__1o9hJ img {
    width: 100%;
  }
.Image-module_captionWrapper__1y0du {
  padding: 25px 25px 25px 0;
  border-bottom: 1px solid #bcbbb4;
}
.Image-module_fullBleedImage__1o9hJ .Image-module_captionWrapper__1y0du {
    width: 100%;
    padding: 0 16px;
    border: none;
    margin-top: 25px;
}
@media (min-width: 640px) {
.Image-module_fullBleedImage__1o9hJ .Image-module_captionWrapper__1y0du {
      width: calc(100% - (100% / 6));
      padding: 0 34px 25px 0;
      border-bottom: 1px solid #bcbbb4;
      margin-left: calc(100% * 1 / 6);
}
    }
@media (min-width: 1025px) {
.Image-module_fullBleedImage__1o9hJ .Image-module_captionWrapper__1y0du {
      width: calc(100% - (100% * 5 / 12) + 40px);
      padding: 0 80px 25px 0;
      margin-left: calc(100% * 5 / 12 - 40px);
}
    }
@media (min-width: 640px) {
.Image-module_captionWrapper__1y0du {
    padding: 25px 25px 25px 34px
}
  }
@media (min-width: 1025px) {
.Image-module_captionWrapper__1y0du {
    padding: 25px 25px 25px 20px
}
  }
.Image-module_caption__2nhUA {
  margin: 0;
  color: #666;
  font-family: 'Suisse Regular'
,
sans-serif;
  font-size: 15px;
  line-height: 1.8;
}

/* @import './key-frame.css'; */
/* @import './font-face.css'; */
:root {
  /* Base colours */

  /* UI colours light theme */

  /* UI colours dark theme */

  /* Background colours */

  /* System colours */
}
/* Dark theme UI elements */
/* Light theme UI elements */
/* UI colours light theme */
/*
--color-light-copy: var(--color-primary);
--color-light-copy-alternate: var(--color-white-darker);
--color-light-label: var(--color-white-darker);
--color-light-link: var(--color-primary);
--color-light-link-hover: var(--color-white-darker);
--color-light-decoration: var(--color-white-darker);
--color-light-disabled: var(--color-grey-60);
*/
/* UI colours dark theme */
/*
--color-dark-copy: var(--color-secondary);
--color-dark-copy-alternate: var(--color-grey-63);
--color-dark-label: var(--color-grey-30);
--color-dark-link: var(--color-grey-20);
--color-dark-link-hover: var(--color-grey-10);
--color-dark-decoration: var(--color-grey-30);
--color-dark-disabled: var(--color-grey-60);
*/
.Slide-module_heading__2KIs0 {
  margin: 0 0 10px 0;
  color: #333;
  font-family: 'Suisse Medium'
,
sans-serif;
}
a:hover .Slide-module_heading__2KIs0,
  a:focus .Slide-module_heading__2KIs0 {
    text-decoration: underline;
  }
.Slide-module_information__34xfK {
  padding: 0 20px;
}
.Slide-module_description__1ZOKE {
  color: #666;
  font-family: 'Suisse Regular'
,
sans-serif;
  font-size: 14px;
}
.Slide-module_image__1j94t img {
    width: auto;
    max-width: 100%;
    max-height: 100%;
    margin: auto auto 0;
  }
.Slide-module_imageContainer__KWlFH {
  margin-top: auto;
}

/* @import './key-frame.css'; */
/* @import './font-face.css'; */
:root {
  /* Base colours */

  /* UI colours light theme */

  /* UI colours dark theme */

  /* Background colours */

  /* System colours */
}
/* Dark theme UI elements */
/* Light theme UI elements */
/* UI colours light theme */
/*
--color-light-copy: var(--color-primary);
--color-light-copy-alternate: var(--color-white-darker);
--color-light-label: var(--color-white-darker);
--color-light-link: var(--color-primary);
--color-light-link-hover: var(--color-white-darker);
--color-light-decoration: var(--color-white-darker);
--color-light-disabled: var(--color-grey-60);
*/
/* UI colours dark theme */
/*
--color-dark-copy: var(--color-secondary);
--color-dark-copy-alternate: var(--color-grey-63);
--color-dark-label: var(--color-grey-30);
--color-dark-link: var(--color-grey-20);
--color-dark-link-hover: var(--color-grey-10);
--color-dark-decoration: var(--color-grey-30);
--color-dark-disabled: var(--color-grey-60);
*/
.Carousel-module_base__2Zak0 {
  padding-top: 60px;
  padding-bottom: 130px;
}
@media (min-width: 640px) {
.Carousel-module_base__2Zak0 {
    padding-top: 130px
}
  }
@media (min-width: 1025px) {
.Carousel-module_base__2Zak0 {
    padding-top: 100px;
    padding-bottom: 60px
}
  }
.Carousel-module_slideWrapper__nqrVO {
  height: 100%;
}
.Carousel-module_carousel__2ILZE {
  position: relative;
  z-index: 20;
  overflow: hidden;
}
.Carousel-module_carousel__2ILZE picture {
    display: flex;
    height: 380px;
    padding: 0;
  }
@media (min-width: 640px) {
.Carousel-module_carousel__2ILZE picture {
      height: 380px
  }
    }
@media (min-width: 1025px) {
.Carousel-module_carousel__2ILZE picture {
      height: 400px
  }
    }
.Carousel-module_carousel__2ILZE p {
    font-size: 15px;
    font-weight: 400;
    line-height: 1.7;
  }
.Carousel-module_carousel__2ILZE .slick-track {
      display: flex;
    }
.Carousel-module_carousel__2ILZE .slick-list {
      position: relative;
      margin: 0 0 24px;
    }
@media (min-width: 640px) {
.Carousel-module_carousel__2ILZE .slick-list {
        margin: 0 40px 24px
    }
      }
@media (min-width: 1025px) {
.Carousel-module_carousel__2ILZE .slick-list {
        margin: 0 80px 48px
    }
      }
.Carousel-module_carousel__2ILZE .slick-slide > div {
        height: 100%;
      }
/* .edges {
  :global {
    .slick-list {
      &::before,
      &::after {
        position: absolute;
        z-index: var(--z-index-carousel-edge);
        top: 0;
        width: 40px;
        height: 100%;
        content: '';
      }

      &::before {
        left: -40px;
        background:
          -webkit-gradient(
            linear,
            left top,
            right top,
            from(rgb(255, 254, 240)),
            to(rgba(0, 0, 0, 0))
          );
      }

      &::after {
        right: -40px;
        background:
          -webkit-gradient(
            linear,
            right top,
            left top,
            from(rgb(255, 254, 240)),
            to(rgba(0, 0, 0, 0))
          );
      }
    }
  }
} */
.Carousel-module_item__2G_2x {
  display: flex;
  flex-direction: column-reverse;
  text-align: center;
  transition:
    background-color 700ms cubic-bezier(0.215, 0.61, 0.355, 1),
    color 700ms cubic-bezier(0.215, 0.61, 0.355, 1);
}
.Carousel-module_item__2G_2x a:active,
  .Carousel-module_item__2G_2x a:hover,
  .Carousel-module_item__2G_2x a:focus {
    background-color: #f6f5e8;
  }
.Carousel-module_link__1gv3s {
  text-decoration: none;
}
.Carousel-module_link__1gv3s:hover,
  .Carousel-module_link__1gv3s:focus {
    color: #000;
  }
.Carousel-module_mobileCarouselIntroductionWrapper__1Y0_X {
  margin: 0 20px 60px;
}
@media (min-width: 640px) {
.Carousel-module_mobileCarouselIntroductionWrapper__1Y0_X {
    margin: 0 40px 100px
}
  }
.Carousel-module_mobileCarouselIntroductionWrapper__1Y0_X p {
    max-width: 100%;
    font-size: 15px;
  }

/* @import './key-frame.css'; */
/* @import './font-face.css'; */
:root {
  /* Base colours */

  /* UI colours light theme */

  /* UI colours dark theme */

  /* Background colours */

  /* System colours */
}
/* Dark theme UI elements */
/* Light theme UI elements */
/* UI colours light theme */
/*
--color-light-copy: var(--color-primary);
--color-light-copy-alternate: var(--color-white-darker);
--color-light-label: var(--color-white-darker);
--color-light-link: var(--color-primary);
--color-light-link-hover: var(--color-white-darker);
--color-light-decoration: var(--color-white-darker);
--color-light-disabled: var(--color-grey-60);
*/
/* UI colours dark theme */
/*
--color-dark-copy: var(--color-secondary);
--color-dark-copy-alternate: var(--color-grey-63);
--color-dark-label: var(--color-grey-30);
--color-dark-link: var(--color-grey-20);
--color-dark-link-hover: var(--color-grey-10);
--color-dark-decoration: var(--color-grey-30);
--color-dark-disabled: var(--color-grey-60);
*/
.DefinitionList-module_base__2OnV9 {
  padding: 0;
  margin: 0;
  list-style: none;
}
.DefinitionList-module_term__38vy8,
.DefinitionList-module_description__1hVLl {
  font-size: 15px;
  line-height: 1.4;
  opacity: 0;
  -webkit-transform: translateY(-20px);
          transform: translateY(-20px);
  transition-duration: 1000ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
@media (min-width: 640px) {
.DefinitionList-module_term__38vy8,
.DefinitionList-module_description__1hVLl {
    line-height: 1.6
}
  }
.DefinitionList-module_term__38vy8 {
  position: relative;
  padding-top: 26px;
  margin-bottom: 4px;
  font-family: 'Suisse Medium'
,
sans-serif;
}
.DefinitionList-module_dark__2bl9J .DefinitionList-module_term__38vy8 {
  color: #333;
  }
.DefinitionList-module_light__3a2GV .DefinitionList-module_term__38vy8 {
    color: #fffef0;
  }
.DefinitionList-module_term__38vy8:nth-of-type(1) {
    transition-delay: calc((1 * 100ms) + 200ms);
  }
.DefinitionList-module_term__38vy8:nth-of-type(2) {
    transition-delay: calc((2 * 100ms) + 200ms);
  }
.DefinitionList-module_term__38vy8:nth-of-type(3) {
    transition-delay: calc((3 * 100ms) + 200ms);
  }
.DefinitionList-module_term__38vy8:nth-of-type(4) {
    transition-delay: calc((4 * 100ms) + 200ms);
  }
@media (min-width: 640px) {
  .DefinitionList-module_term__38vy8 {
    padding-top: 14px
}
  }
.DefinitionList-module_slideIn__unr4R {
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.DefinitionList-module_description__1hVLl {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 26px;
  margin-left: 0;
  font-family: 'Suisse Regular'
,
sans-serif;
}
.DefinitionList-module_dark__2bl9J .DefinitionList-module_description__1hVLl {
  color: #666;
  }
.DefinitionList-module_light__3a2GV .DefinitionList-module_description__1hVLl {
    color: #e0dfd6;
  }
.DefinitionList-module_description__1hVLl:nth-of-type(1) {
    transition-delay: calc((1 * 100ms) + 200ms);
  }
.DefinitionList-module_description__1hVLl:nth-of-type(2) {
    transition-delay: calc((2 * 100ms) + 200ms);
  }
.DefinitionList-module_description__1hVLl:nth-of-type(3) {
    transition-delay: calc((3 * 100ms) + 200ms);
  }
.DefinitionList-module_description__1hVLl:nth-of-type(4) {
    transition-delay: calc((4 * 100ms) + 200ms);
  }
@media (min-width: 640px) {
  .DefinitionList-module_description__1hVLl {
    padding-bottom: 14px
}
  }
.DefinitionList-module_description__1hVLl:not(:last-child) {
    border-bottom: 1px solid #d5d4c9;
  }

:root {
  /* Base colours */

  /* UI colours light theme */

  /* UI colours dark theme */

  /* Background colours */

  /* System colours */
}
.transitions-module_fadeEnter__39Yw4 {
  opacity: 0;
}
.transitions-module_fadeEnterActive__Jhp1k {
  opacity: 1;
  transition: opacity 500ms cubic-bezier(0.215, 0.61, 0.355, 1);
}
.transitions-module_fadeEnterDone__1pN9W {
  opacity: 1;
}
.transitions-module_fadeExit__3yr99 {
  opacity: 1;
}
.transitions-module_fadeExitActive__2at34 {
  opacity: 0;
  transition: opacity 500ms cubic-bezier(0.215, 0.61, 0.355, 1);
}
.transitions-module_fadeExitDone__242dB {
  opacity: 0;
}
.transitions-module_shiftInDownEnter__1KxQa {
  opacity: 0;
  -webkit-transform: translate3d(0, -20px, 0);
          transform: translate3d(0, -20px, 0);
}
.transitions-module_shiftInDownEnterActive__3Cc7U {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  transition:
    opacity 1500ms cubic-bezier(0.645, 0.045, 0.355, 1),
    -webkit-transform 1500ms cubic-bezier(0.215, 0.61, 0.355, 1);
  transition:
    transform 1500ms cubic-bezier(0.215, 0.61, 0.355, 1),
    opacity 1500ms cubic-bezier(0.645, 0.045, 0.355, 1);
  transition:
    transform 1500ms cubic-bezier(0.215, 0.61, 0.355, 1),
    opacity 1500ms cubic-bezier(0.645, 0.045, 0.355, 1),
    -webkit-transform 1500ms cubic-bezier(0.215, 0.61, 0.355, 1);
}
.transitions-module_shiftInDownEnterDone__1weN2 {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
.transitions-module_shiftInDownExit__QzfmH {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
.transitions-module_shiftInDownExitActive__1oIXc {
  opacity: 0;
  -webkit-transform: translate3d(0, -20px, 0);
          transform: translate3d(0, -20px, 0);
  transition:
    opacity 1500ms cubic-bezier(0.645, 0.045, 0.355, 1),
    -webkit-transform 1500ms cubic-bezier(0.215, 0.61, 0.355, 1);
  transition:
    transform 1500ms cubic-bezier(0.215, 0.61, 0.355, 1),
    opacity 1500ms cubic-bezier(0.645, 0.045, 0.355, 1);
  transition:
    transform 1500ms cubic-bezier(0.215, 0.61, 0.355, 1),
    opacity 1500ms cubic-bezier(0.645, 0.045, 0.355, 1),
    -webkit-transform 1500ms cubic-bezier(0.215, 0.61, 0.355, 1);
}
.transitions-module_shiftInDownExitDone__38h-E {
  opacity: 0;
  -webkit-transform: translate3d(0, -20px, 0);
          transform: translate3d(0, -20px, 0);
}
.transitions-module_shiftInLeftEnter__JZlOS {
  opacity: 0;
  -webkit-transform: translate3d(20px, 0, 0);
          transform: translate3d(20px, 0, 0);
}
.transitions-module_shiftInLeftEnterActive__2PTyF {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  transition:
    opacity 1000ms cubic-bezier(0.645, 0.045, 0.355, 1),
    -webkit-transform 1500ms cubic-bezier(0.215, 0.61, 0.355, 1);
  transition:
    transform 1500ms cubic-bezier(0.215, 0.61, 0.355, 1),
    opacity 1000ms cubic-bezier(0.645, 0.045, 0.355, 1);
  transition:
    transform 1500ms cubic-bezier(0.215, 0.61, 0.355, 1),
    opacity 1000ms cubic-bezier(0.645, 0.045, 0.355, 1),
    -webkit-transform 1500ms cubic-bezier(0.215, 0.61, 0.355, 1);
}
.transitions-module_shiftInLeftEnterDone__29wBA {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
.transitions-module_shiftInLeftExit__3-XBX {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
.transitions-module_shiftInLeftExitActive__3HhM- {
  opacity: 0;
  -webkit-transform: translate3d(20px, 0, 0);
          transform: translate3d(20px, 0, 0);
  transition:
    opacity 1000ms cubic-bezier(0.645, 0.045, 0.355, 1),
    -webkit-transform 1500ms cubic-bezier(0.215, 0.61, 0.355, 1);
  transition:
    transform 1500ms cubic-bezier(0.215, 0.61, 0.355, 1),
    opacity 1000ms cubic-bezier(0.645, 0.045, 0.355, 1);
  transition:
    transform 1500ms cubic-bezier(0.215, 0.61, 0.355, 1),
    opacity 1000ms cubic-bezier(0.645, 0.045, 0.355, 1),
    -webkit-transform 1500ms cubic-bezier(0.215, 0.61, 0.355, 1);
}
.transitions-module_shiftInLeftExitDone__1Scz- {
  opacity: 0;
  -webkit-transform: translate3d(20px, 0, 0);
          transform: translate3d(20px, 0, 0);
}
.transitions-module_slideDownEnter__1ozxS {
  -webkit-transform: translate3d(0, -100%, 0);
          transform: translate3d(0, -100%, 0);
  visibility: hidden;
}
.transitions-module_slideDownEnterActive__19lkp {
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  transition:
    visibility 500ms cubic-bezier(0.215, 0.61, 0.355, 1),
    -webkit-transform 500ms cubic-bezier(0.215, 0.61, 0.355, 1);
  transition:
    transform 500ms cubic-bezier(0.215, 0.61, 0.355, 1),
    visibility 500ms cubic-bezier(0.215, 0.61, 0.355, 1);
  transition:
    transform 500ms cubic-bezier(0.215, 0.61, 0.355, 1),
    visibility 500ms cubic-bezier(0.215, 0.61, 0.355, 1),
    -webkit-transform 500ms cubic-bezier(0.215, 0.61, 0.355, 1);
  visibility: visible;
}
.transitions-module_slideDownEnterDone__1daQU {
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  visibility: visible;
}
.transitions-module_slideDownExit__1zFf8 {
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  visibility: visible;
}
.transitions-module_slideDownExitActive__LriBR {
  -webkit-transform: translate3d(0, -100%, 0);
          transform: translate3d(0, -100%, 0);
  transition:
    visibility 500ms cubic-bezier(0.215, 0.61, 0.355, 1),
    -webkit-transform 500ms cubic-bezier(0.215, 0.61, 0.355, 1);
  transition:
    transform 500ms cubic-bezier(0.215, 0.61, 0.355, 1),
    visibility 500ms cubic-bezier(0.215, 0.61, 0.355, 1);
  transition:
    transform 500ms cubic-bezier(0.215, 0.61, 0.355, 1),
    visibility 500ms cubic-bezier(0.215, 0.61, 0.355, 1),
    -webkit-transform 500ms cubic-bezier(0.215, 0.61, 0.355, 1);
  visibility: hidden;
}
.transitions-module_slideDownExitDone__3NwvB {
  -webkit-transform: translate3d(0, -100%, 0);
          transform: translate3d(0, -100%, 0);
  visibility: hidden;
}
.transitions-module_slideRightEnter__3ktdD {
  -webkit-transform: translate3d(-100%, 0, 0);
          transform: translate3d(-100%, 0, 0);
  visibility: hidden;
}
.transitions-module_slideRightEnterActive__LMADo {
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  transition:
    visibility 500ms cubic-bezier(0.215, 0.61, 0.355, 1),
    -webkit-transform 500ms cubic-bezier(0.215, 0.61, 0.355, 1);
  transition:
    transform 500ms cubic-bezier(0.215, 0.61, 0.355, 1),
    visibility 500ms cubic-bezier(0.215, 0.61, 0.355, 1);
  transition:
    transform 500ms cubic-bezier(0.215, 0.61, 0.355, 1),
    visibility 500ms cubic-bezier(0.215, 0.61, 0.355, 1),
    -webkit-transform 500ms cubic-bezier(0.215, 0.61, 0.355, 1);
  visibility: visible;
}
.transitions-module_slideRightEnterDone__2B8eF {
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  visibility: visible;
}
.transitions-module_slideRightExit__3J_wV {
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  visibility: visible;
}
.transitions-module_slideRightExitActive__2PwBm {
  -webkit-transform: translate3d(-100%, 0, 0);
          transform: translate3d(-100%, 0, 0);
  transition:
    visibility 500ms cubic-bezier(0.215, 0.61, 0.355, 1),
    -webkit-transform 500ms cubic-bezier(0.215, 0.61, 0.355, 1);
  transition:
    transform 500ms cubic-bezier(0.215, 0.61, 0.355, 1),
    visibility 500ms cubic-bezier(0.215, 0.61, 0.355, 1);
  transition:
    transform 500ms cubic-bezier(0.215, 0.61, 0.355, 1),
    visibility 500ms cubic-bezier(0.215, 0.61, 0.355, 1),
    -webkit-transform 500ms cubic-bezier(0.215, 0.61, 0.355, 1);
  visibility: hidden;
}
.transitions-module_slideRightExitDone__1KOgp {
  -webkit-transform: translate3d(-100%, 0, 0);
          transform: translate3d(-100%, 0, 0);
  visibility: hidden;
}
.transitions-module_slowFadeEnter__1WmWT {
  opacity: 0;
}
.transitions-module_slowFadeEnterActive__2uxS0 {
  opacity: 1;
  transition: opacity 1500ms cubic-bezier(0.215, 0.61, 0.355, 1);
}
.transitions-module_slowFadeEnterDone__1OJv_ {
  opacity: 1;
}
.transitions-module_slowFadeExit__pp458 {
  opacity: 1;
}
.transitions-module_slowFadeExitActive__1HYrk {
  opacity: 0;
  transition: opacity 1500ms cubic-bezier(0.215, 0.61, 0.355, 1);
}
.transitions-module_slowFadeExitDone__1l2dO {
  opacity: 0;
}
.transitions-module_zoomEnter__14hXw {
  opacity: 0;
  -webkit-transform: scale3d(0.9, 0.9, 0.9);
          transform: scale3d(0.9, 0.9, 0.9);
}
.transitions-module_zoomEnterActive__1Ix7t {
  opacity: 1;
  -webkit-transform: scale3d(1, 1, 1);
          transform: scale3d(1, 1, 1);
  transition:
    opacity 500ms cubic-bezier(0.215, 0.61, 0.355, 1),
    -webkit-transform 500ms cubic-bezier(0.215, 0.61, 0.355, 1);
  transition:
    transform 500ms cubic-bezier(0.215, 0.61, 0.355, 1),
    opacity 500ms cubic-bezier(0.215, 0.61, 0.355, 1);
  transition:
    transform 500ms cubic-bezier(0.215, 0.61, 0.355, 1),
    opacity 500ms cubic-bezier(0.215, 0.61, 0.355, 1),
    -webkit-transform 500ms cubic-bezier(0.215, 0.61, 0.355, 1);
}
.transitions-module_zoomEnterDone__2z-Jn {
  opacity: 1;
  -webkit-transform: scale3d(1, 1, 1);
          transform: scale3d(1, 1, 1);
}
.transitions-module_zoomExit__2YNjS {
  opacity: 1;
  -webkit-transform: scale3d(1, 1, 1);
          transform: scale3d(1, 1, 1);
}
.transitions-module_zoomExitActive__2j1mx {
  opacity: 0;
  -webkit-transform: scale3d(0.9, 0.9, 0.9);
          transform: scale3d(0.9, 0.9, 0.9);
  transition:
    opacity 500ms cubic-bezier(0.215, 0.61, 0.355, 1),
    -webkit-transform 500ms cubic-bezier(0.215, 0.61, 0.355, 1);
  transition:
    transform 500ms cubic-bezier(0.215, 0.61, 0.355, 1),
    opacity 500ms cubic-bezier(0.215, 0.61, 0.355, 1);
  transition:
    transform 500ms cubic-bezier(0.215, 0.61, 0.355, 1),
    opacity 500ms cubic-bezier(0.215, 0.61, 0.355, 1),
    -webkit-transform 500ms cubic-bezier(0.215, 0.61, 0.355, 1);
}
.transitions-module_zoomExitDone__23u9a {
  opacity: 0;
  -webkit-transform: scale3d(0.9, 0.9, 0.9);
          transform: scale3d(0.9, 0.9, 0.9);
}

:root {
  /* Base colours */

  /* UI colours light theme */

  /* UI colours dark theme */

  /* Background colours */

  /* System colours */
}
/* Dark theme UI elements */
/* Light theme UI elements */
/* UI colours light theme */
/*
--color-light-copy: var(--color-primary);
--color-light-copy-alternate: var(--color-white-darker);
--color-light-label: var(--color-white-darker);
--color-light-link: var(--color-primary);
--color-light-link-hover: var(--color-white-darker);
--color-light-decoration: var(--color-white-darker);
--color-light-disabled: var(--color-grey-60);
*/
/* UI colours dark theme */
/*
--color-dark-copy: var(--color-secondary);
--color-dark-copy-alternate: var(--color-grey-63);
--color-dark-label: var(--color-grey-30);
--color-dark-link: var(--color-grey-20);
--color-dark-link-hover: var(--color-grey-10);
--color-dark-decoration: var(--color-grey-30);
--color-dark-disabled: var(--color-grey-60);
*/
.Overlay-module_base__21l1K {
  position: fixed;
  z-index: 80;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  pointer-events: none;
}
.Overlay-module_base__21l1K.Overlay-module_isVisible__2NJpm {
    pointer-events: auto;
  }

:root {
  /* Base colours */

  /* UI colours light theme */

  /* UI colours dark theme */

  /* Background colours */

  /* System colours */
}
/* Dark theme UI elements */
/* Light theme UI elements */
/* UI colours light theme */
/*
--color-light-copy: var(--color-primary);
--color-light-copy-alternate: var(--color-white-darker);
--color-light-label: var(--color-white-darker);
--color-light-link: var(--color-primary);
--color-light-link-hover: var(--color-white-darker);
--color-light-decoration: var(--color-white-darker);
--color-light-disabled: var(--color-grey-60);
*/
/* UI colours dark theme */
/*
--color-dark-copy: var(--color-secondary);
--color-dark-copy-alternate: var(--color-grey-63);
--color-dark-label: var(--color-grey-30);
--color-dark-link: var(--color-grey-20);
--color-dark-link-hover: var(--color-grey-10);
--color-dark-decoration: var(--color-grey-30);
--color-dark-disabled: var(--color-grey-60);
*/
.DialogBanner-module_base__1sS5X {
  position: fixed;
  z-index: 140;
  right: 0;
  left: 0;
  padding: 1.5rem 40px;
}
@media (max-width: 639px) {
.DialogBanner-module_base__1sS5X {
    padding: 1.5rem 1.5rem 2.2rem 1.5rem
}
  }
@media (min-width: 640px) {
.DialogBanner-module_base__1sS5X {
    display: grid;
    grid-template-columns: 15% 63% 22%
}
  }
@media (min-width: 1025px) {
.DialogBanner-module_base__1sS5X {
    grid-template-columns: 20% 60% 20%
}
  }
.DialogBanner-module_top__jLPxl {
  top: 0;
}
.DialogBanner-module_bottom__2hfmb {

  bottom: 0;
}
/* @TODO Handle bottom prop */
.DialogBanner-module_isVisible__1i46z.DialogBanner-module_bottom__2hfmb {
  }
.DialogBanner-module_dark__2cbOO {
  background-color: #333;
}
.DialogBanner-module_black__2sEch {
  background-color: #252525;
}
@media (min-width: 640px) {
.DialogBanner-module_contentContainer__jzerV {
    padding: 0 24px
}
  }
.DialogBanner-module_heading__2qJht {
  color: #fffef2;
}
@media (max-width: 639px) {
.DialogBanner-module_heading__2qJht {
    margin-top: 28px;
    margin-bottom: 10px
}
  }
.DialogBanner-module_message__3xWGh {
  color: #d5d4c9;
  font-size: 15px;
}
@media (max-width: 639px) {
    .DialogBanner-module_message__3xWGh:last-of-type {
      margin-bottom: 14px;
    }
  }
.DialogBanner-module_warningIcon__HKWtl {
  fill: #999;
}
.DialogBanner-module_closeButton__2XMqf {
  position: absolute;
  top: 28px;
  right: 40px;
}
@media (max-width: 639px) {
.DialogBanner-module_closeButton__2XMqf {
    top: 24px;
    right: 24px
}
  }
.DialogBanner-module_closeButton__2XMqf:hover svg, .DialogBanner-module_closeButton__2XMqf:focus svg {
      fill: white;
    }
.DialogBanner-module_closeIcon__1UkP7 {
  fill: #999;
}
@media (min-width: 640px) {
.DialogBanner-module_closeContainer__3RJ-U {
    align-self: flex-end;
    text-align: right
}
  }
.DialogBanner-module_ctaButton__1GEq- {
  font-size: 15px;
}

/* @import './key-frame.css'; */
/* @import './font-face.css'; */
:root {
  /* Base colours */

  /* UI colours light theme */

  /* UI colours dark theme */

  /* Background colours */

  /* System colours */
}
/* Dark theme UI elements */
/* Light theme UI elements */
/* UI colours light theme */
/*
--color-light-copy: var(--color-primary);
--color-light-copy-alternate: var(--color-white-darker);
--color-light-label: var(--color-white-darker);
--color-light-link: var(--color-primary);
--color-light-link-hover: var(--color-white-darker);
--color-light-decoration: var(--color-white-darker);
--color-light-disabled: var(--color-grey-60);
*/
/* UI colours dark theme */
/*
--color-dark-copy: var(--color-secondary);
--color-dark-copy-alternate: var(--color-grey-63);
--color-dark-label: var(--color-grey-30);
--color-dark-link: var(--color-grey-20);
--color-dark-link-hover: var(--color-grey-10);
--color-dark-decoration: var(--color-grey-30);
--color-dark-disabled: var(--color-grey-60);
*/
.Figure-module_base__2Vmj2 {
  margin: 0;
}
.Figure-module_caption__187du {
  margin: 0;
  font-family: 'Suisse Regular'
,
sans-serif;
  font-size: 14px;
  line-height: 1.6;
}
.Figure-module_dark__1Gy-5 .Figure-module_caption__187du {
    color: #333;
  }
.Figure-module_light__wv1hI .Figure-module_caption__187du {
    color: #fffef0;
  }
.Figure-module_caption__187du p:first-of-type {
      margin-top: 0;
    }
.Figure-module_caption__187du p:last-of-type {
      margin-bottom: 0;
    }
.Figure-module_captionWrapper__1WrIr {
  padding-top: 16px;
  padding-right: 16px;
  padding-bottom: 16px;
  margin: 0;

  /* .fullBleedImage & {
    width: 100%;
    padding: 0 var(--layout-sm-spacing);
    border: none;
    margin-top: 25px;

    @media (--viewport-md) {
      width: calc(100% - (100% / 6));
      padding: 0 var(--layout-md-spacing) 25px 0;
      border-bottom: 1px solid var(--color-grey-60);
      margin-left: calc(100% * 1 / 6);
    }

    @media (--viewport-lg) {
      width: calc(100% - (100% * 5 / 12) + 40px);
      padding: 0 80px 25px 0;
      margin-left: calc(100% * 5 / 12 - 40px);
    }
  } */
}
@media (min-width: 640px) {
.Figure-module_captionWrapper__1WrIr {
    padding-top: calc(34px / 2);
    padding-right: 34px;
    padding-bottom: calc(34px / 2)
}
  }
@media (min-width: 1025px) {
.Figure-module_captionWrapper__1WrIr {
    padding-top: calc(40px / 2);
    padding-right: 40px;
    padding-bottom: calc(40px / 2)
}
  }
.Figure-module_border__5rGIx {
  border-bottom: 1px solid #bcbbb4;
}

/* @import './key-frame.css'; */
/* @import './font-face.css'; */
:root {
  /* Base colours */

  /* UI colours light theme */

  /* UI colours dark theme */

  /* Background colours */

  /* System colours */
}
/* Dark theme UI elements */
/* Light theme UI elements */
/* UI colours light theme */
/*
--color-light-copy: var(--color-primary);
--color-light-copy-alternate: var(--color-white-darker);
--color-light-label: var(--color-white-darker);
--color-light-link: var(--color-primary);
--color-light-link-hover: var(--color-white-darker);
--color-light-decoration: var(--color-white-darker);
--color-light-disabled: var(--color-grey-60);
*/
/* UI colours dark theme */
/*
--color-dark-copy: var(--color-secondary);
--color-dark-copy-alternate: var(--color-grey-63);
--color-dark-label: var(--color-grey-30);
--color-dark-link: var(--color-grey-20);
--color-dark-link-hover: var(--color-grey-10);
--color-dark-decoration: var(--color-grey-30);
--color-dark-disabled: var(--color-grey-60);
*/
.DoubleImage-module_base__3dS_7 {
  padding: 34px;
}
@media (min-width: 640px) {
.DoubleImage-module_base__3dS_7 {
    display: flex;
    justify-content: space-between;
    padding: 34px
}
  }
@media (min-width: 1025px) {
.DoubleImage-module_base__3dS_7 {
    padding: 34px calc(34px * 2)
}
  }
.DoubleImage-module_figure___aWCg {
  margin-bottom: calc(16px * 2);
}
@media (min-width: 640px) {
.DoubleImage-module_figure___aWCg {
    display: flex;
    flex-grow: 0;
            flex-shrink: 0;
            flex-basis: calc(50% - 34px / 4);
    flex-direction: column;
    margin-bottom: auto;
    margin-bottom: 0
}
  }
@media (min-width: 1025px) {
.DoubleImage-module_figure___aWCg {
    flex-grow: 0;
            flex-shrink: 0;
            flex-basis: calc(50% - 40px / 4)
}
  }

:root {
  /* Base colours */

  /* UI colours light theme */

  /* UI colours dark theme */

  /* Background colours */

  /* System colours */
}
/* Dark theme UI elements */
/* Light theme UI elements */
/* UI colours light theme */
/*
--color-light-copy: var(--color-primary);
--color-light-copy-alternate: var(--color-white-darker);
--color-light-label: var(--color-white-darker);
--color-light-link: var(--color-primary);
--color-light-link-hover: var(--color-white-darker);
--color-light-decoration: var(--color-white-darker);
--color-light-disabled: var(--color-grey-60);
*/
/* UI colours dark theme */
/*
--color-dark-copy: var(--color-secondary);
--color-dark-copy-alternate: var(--color-grey-63);
--color-dark-label: var(--color-grey-30);
--color-dark-link: var(--color-grey-20);
--color-dark-link-hover: var(--color-grey-10);
--color-dark-decoration: var(--color-grey-30);
--color-dark-disabled: var(--color-grey-60);
*/
.FlyinPanel-module_base__1Tlwv {
  position: fixed;
  z-index: 110;
  top: 0;
  bottom: 0;
  left: 0;
  justify-content: center;
  padding:
    calc(16px * 3) calc(16px * 2)
    calc(16px * 2);
  background-color: #f6f5e8;
  font-family: 'Suisse Regular'
,
sans-serif;
  font-size: 15px;
  line-height: 1.4;
  overflow-y: auto;
}
@media (max-width: 639px) {
.FlyinPanel-module_base__1Tlwv {
    width: auto
}
  }
@media (min-width: 640px) {
.FlyinPanel-module_base__1Tlwv {
    width: 50%;
    padding:
      calc(34px * 3) 34px
      34px
}
  }
@media (min-width: 1025px) {
.FlyinPanel-module_base__1Tlwv {
    padding:
      calc(40px * 3)
      calc(40px * 4) 40px
      40px
}
  }
.FlyinPanel-module_closeButton__1aA6Z.FlyinPanel-module_closeButton__1aA6Z {
  position: absolute;
  top: 18px;
  right: 20px;
  padding: 2px 4px 0 4px;
}

/* @import './key-frame.css'; */
/* @import './font-face.css'; */
:root {
  /* Base colours */

  /* UI colours light theme */

  /* UI colours dark theme */

  /* Background colours */

  /* System colours */
}
/* Dark theme UI elements */
/* Light theme UI elements */
/* UI colours light theme */
/*
--color-light-copy: var(--color-primary);
--color-light-copy-alternate: var(--color-white-darker);
--color-light-label: var(--color-white-darker);
--color-light-link: var(--color-primary);
--color-light-link-hover: var(--color-white-darker);
--color-light-decoration: var(--color-white-darker);
--color-light-disabled: var(--color-grey-60);
*/
/* UI colours dark theme */
/*
--color-dark-copy: var(--color-secondary);
--color-dark-copy-alternate: var(--color-grey-63);
--color-dark-label: var(--color-grey-30);
--color-dark-link: var(--color-grey-20);
--color-dark-link-hover: var(--color-grey-10);
--color-dark-decoration: var(--color-grey-30);
--color-dark-disabled: var(--color-grey-60);
*/
.List-module_base__wbAa2 {
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
  list-style: none;
}
.List-module_dark__396s9 {
  color: #333;
}
.List-module_light__2gCzU {
  color: #fffef0;
}
.List-module_item__Ru4zd:not(:last-child) {
    margin-bottom: 16px;
  }

/* @import './key-frame.css'; */
/* @import './font-face.css'; */
:root {
  /* Base colours */

  /* UI colours light theme */

  /* UI colours dark theme */

  /* Background colours */

  /* System colours */
}
/* Dark theme UI elements */
/* Light theme UI elements */
/* UI colours light theme */
/*
--color-light-copy: var(--color-primary);
--color-light-copy-alternate: var(--color-white-darker);
--color-light-label: var(--color-white-darker);
--color-light-link: var(--color-primary);
--color-light-link-hover: var(--color-white-darker);
--color-light-decoration: var(--color-white-darker);
--color-light-disabled: var(--color-grey-60);
*/
/* UI colours dark theme */
/*
--color-dark-copy: var(--color-secondary);
--color-dark-copy-alternate: var(--color-grey-63);
--color-dark-label: var(--color-grey-30);
--color-dark-link: var(--color-grey-20);
--color-dark-link-hover: var(--color-grey-10);
--color-dark-decoration: var(--color-grey-30);
--color-dark-disabled: var(--color-grey-60);
*/
.SubNav-module_base__3LIXq {
  margin: 16px 0;
}
@media (min-width: 640px) {
.SubNav-module_base__3LIXq {
    margin: 34px 0
}
  }
@media (min-width: 1025px) {
.SubNav-module_base__3LIXq {
    margin: 40px 0
}
  }
.SubNav-module_heading__25EKa {
  font-family: 'Suisse Regular'
,
sans-serif;
  line-height: 1.6;
  transition: all 300ms cubic-bezier(0.215, 0.61, 0.355, 1);
}
.SubNav-module_dark__2Xs3T .SubNav-module_heading__25EKa {
  color: #666;
  }
.SubNav-module_light__1B3lz .SubNav-module_heading__25EKa {
    color: #e0dfd6;
  }
.SubNav-module_itemList__25xiF {
  font-family: 'Suisse Medium'
,
sans-serif;
  font-size: 17px;
}

:root {
  /* Base colours */

  /* UI colours light theme */

  /* UI colours dark theme */

  /* Background colours */

  /* System colours */
}
/* Dark theme UI elements */
/* Light theme UI elements */
/* UI colours light theme */
/*
--color-light-copy: var(--color-primary);
--color-light-copy-alternate: var(--color-white-darker);
--color-light-label: var(--color-white-darker);
--color-light-link: var(--color-primary);
--color-light-link-hover: var(--color-white-darker);
--color-light-decoration: var(--color-white-darker);
--color-light-disabled: var(--color-grey-60);
*/
/* UI colours dark theme */
/*
--color-dark-copy: var(--color-secondary);
--color-dark-copy-alternate: var(--color-grey-63);
--color-dark-label: var(--color-grey-30);
--color-dark-link: var(--color-grey-20);
--color-dark-link-hover: var(--color-grey-10);
--color-dark-decoration: var(--color-grey-30);
--color-dark-disabled: var(--color-grey-60);
*/
.HeroWithContent-module_base__2TLfg {
  /* @extend %layout-full-bleed-negative-margin; */

  position: relative;
  box-sizing: border-box;
  margin-bottom: 34px;
}
.HeroWithContent-module_base__2TLfg *,
    .HeroWithContent-module_base__2TLfg *::before,
    .HeroWithContent-module_base__2TLfg *::after {
      box-sizing: inherit;
    }
@media (min-width: 1025px) {
.HeroWithContent-module_base__2TLfg {
    margin-bottom: calc(40px * 1.5)
}
  }
.HeroWithContent-module_backgroundImagePrototype__I0gCn {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.HeroWithContent-module_noTopMargin__2CZYI {
  margin-top: 0;
}
@media (min-width: 640px) {
.HeroWithContent-module_image__5CG8l {
    position: absolute;
    top: 0;
    left: 0;
    padding: 0;
    background-color: transparent
}
  }
.HeroWithContent-module_contentWrapper__2RZNu {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  padding: calc(16px * 2);
}
@media (min-width: 640px) {
.HeroWithContent-module_content__3Ej8Z {
    /* flex-basis doesn't account for box-sizing:border-box */
    /* stylelint-disable */
    width: calc(100% * 7 / 12);
    flex-basis: auto;
    /* IE is consistently 1px too wide with the standard calc value */
    /* here is a hack so terrible we have to tell the linter to ignore it */
    /* stylelint-enable */

    padding-top:
      calc(
        (95px + 80px - 10px)
      );
    margin-left: calc((100% * 4 / 12))
}
    .HeroWithContent-module_content__3Ej8Z _:-ms-input-placeholder,
  :root .HeroWithContent-module_content__3Ej8Z {
    width: calc((100% * 7 / 12) - 1px);
  }
  }
@media (min-width: 1025px) {
.HeroWithContent-module_content__3Ej8Z {
    /* flex-basis doesn't account for box-sizing:border-box */
    /* stylelint-disable */
    width: calc(100% * 5 / 12);
    flex-basis: auto;
    /* IE is consistently 1px too wide with the standard calc value */
    /* here is a hack so terrible we have to tell the linter to ignore it */
    /* stylelint-enable */

    padding-top:
      calc(
        (120px + 80px - 5px)
      );
    margin-left: calc((100% / 12) * 3)
}
    .HeroWithContent-module_content__3Ej8Z _:-ms-input-placeholder,
  :root .HeroWithContent-module_content__3Ej8Z {
    width: calc((100% * 5 / 12) - 1px);
  }
  }
.HeroWithContent-module_primaryTitle__prAEM,
.HeroWithContent-module_secondayTitle__1iHSW {
  margin: 0;
}

/* @import './key-frame.css'; */
/* @import './font-face.css'; */
:root {
  /* Base colours */

  /* UI colours light theme */

  /* UI colours dark theme */

  /* Background colours */

  /* System colours */
}
/* Dark theme UI elements */
/* Light theme UI elements */
/* UI colours light theme */
/*
--color-light-copy: var(--color-primary);
--color-light-copy-alternate: var(--color-white-darker);
--color-light-label: var(--color-white-darker);
--color-light-link: var(--color-primary);
--color-light-link-hover: var(--color-white-darker);
--color-light-decoration: var(--color-white-darker);
--color-light-disabled: var(--color-grey-60);
*/
/* UI colours dark theme */
/*
--color-dark-copy: var(--color-secondary);
--color-dark-copy-alternate: var(--color-grey-63);
--color-dark-label: var(--color-grey-30);
--color-dark-link: var(--color-grey-20);
--color-dark-link-hover: var(--color-grey-10);
--color-dark-decoration: var(--color-grey-30);
--color-dark-disabled: var(--color-grey-60);
*/
.ImageWithHeaderAndContent-module_base__2-Y4B {
  position: relative;
  background-color: #f6f5e8;
}
@media (min-width: 1025px) {
.ImageWithHeaderAndContent-module_base__2-Y4B {
    display: flex;
    min-height: 100vh;
    flex-direction: row
}
  }
@media (min-width: 1025px) {
.ImageWithHeaderAndContent-module_reverse__3g8sq {
    display: flex;
    flex-direction: row-reverse
}
  }
@media (min-width: 640px) and (max-width: 1024px) {
.ImageWithHeaderAndContent-module_content__3tIQ4 {
    width: 50%;
    flex-basis: auto;
    margin-left: 20px
}
  }
/* .original.dark .content {
  border-top: 2px solid var(--color-grey-20);
}

.original.light .content {
  border-top: 2px solid var(--color-light-copy);
}

.dark.reverse .heading {
  padding-bottom: 20px;
  border-bottom: 2px solid var(--color-grey-20);
}

.light.reverse .heading {
  padding-bottom: 20px;
  border-bottom: 2px solid var(--color-light-copy);
} */
.ImageWithHeaderAndContent-module_dark__19xnz .ImageWithHeaderAndContent-module_content__3tIQ4 {
  border-top: 2px solid #4a4a4a;
}
.ImageWithHeaderAndContent-module_light__XhSI- .ImageWithHeaderAndContent-module_content__3tIQ4 {
  border-top: 2px solid #fffef0;
}
.ImageWithHeaderAndContent-module_wrapper__hwV4A {
  padding: 76px 20px;
}
@media (min-width: 640px) {
.ImageWithHeaderAndContent-module_wrapper__hwV4A {
    display: flex;
    flex-basis: auto;
    padding: 110px 34px
}
  }
@media (min-width: 1025px) {
.ImageWithHeaderAndContent-module_wrapper__hwV4A {
    width: 66.66667%;
    flex-basis: auto;
    flex-direction: column;
    padding: 115px 0;
    margin: auto
}
  }
@media (min-width: 1025px) {
.ImageWithHeaderAndContent-module_container__38jRk,
.ImageWithHeaderAndContent-module_mediaWrapper__2ebNj {
    display: flex;
    width: 50%;
    flex-basis: auto
}
  }
.ImageWithHeaderAndContent-module_container__38jRk {
  font-family: 'Suisse Regular'
,
sans-serif;
  font-size: 15px;
  line-height: 1.6;
}
@media (min-width: 640px) and (max-width: 1024px) {
.ImageWithHeaderAndContent-module_header__7yt9k.ImageWithHeaderAndContent-module_header__7yt9k {
    width: 50%;
    flex-basis: auto;
    margin-right: 20px
}
  }
.ImageWithHeaderAndContent-module_mediaWrapper__2ebNj {
  height: auto;
}
.ImageWithHeaderAndContent-module_mediaWrapper__2ebNj img {
    width: 100%;
    height: 100%;
    flex: 1 0 auto;
    line-height: 0;
    object-fit: cover;
    -webkit-transform: none;
            transform: none;
  }
.ImageWithHeaderAndContent-module_mediaWrapper__2ebNj picture {
    padding: 0;
    margin: 0;
    line-height: 0;
  }
.ImageWithHeaderAndContent-module_isFullWidthImage__1SW5s {
  height: 100%;
}
@media (min-width: 1025px) {
.ImageWithHeaderAndContent-module_isFullWidthImage__1SW5s {
    position: absolute;
    z-index: 10;
    width: 100%
}
  }
.ImageWithHeaderAndContent-module_hasFullWidthImage__X0Buc {
  z-index: 20;
  height: 100%;
}
@media (min-width: 1025px) {
.ImageWithHeaderAndContent-module_hasFullWidthImage__X0Buc {
    margin: auto 60px auto calc(50% + 60px)
}
  }
@media (min-width: 1025px) {
.ImageWithHeaderAndContent-module_reverse__3g8sq .ImageWithHeaderAndContent-module_hasFullWidthImage__X0Buc {
      margin: auto calc(50% + 60px) auto 60px
  }
    }
.ImageWithHeaderAndContent-module_description__2w0J5 {
  padding-top: 20px;
  font-size: 15px;
}
.ImageWithHeaderAndContent-module_description__2w0J5:last-of-type {
    margin-bottom: 26px;
  }

/* @import './key-frame.css'; */
/* @import './font-face.css'; */
:root {
  /* Base colours */

  /* UI colours light theme */

  /* UI colours dark theme */

  /* Background colours */

  /* System colours */
}
/* Dark theme UI elements */
/* Light theme UI elements */
/* UI colours light theme */
/*
--color-light-copy: var(--color-primary);
--color-light-copy-alternate: var(--color-white-darker);
--color-light-label: var(--color-white-darker);
--color-light-link: var(--color-primary);
--color-light-link-hover: var(--color-white-darker);
--color-light-decoration: var(--color-white-darker);
--color-light-disabled: var(--color-grey-60);
*/
/* UI colours dark theme */
/*
--color-dark-copy: var(--color-secondary);
--color-dark-copy-alternate: var(--color-grey-63);
--color-dark-label: var(--color-grey-30);
--color-dark-link: var(--color-grey-20);
--color-dark-link-hover: var(--color-grey-10);
--color-dark-decoration: var(--color-grey-30);
--color-dark-disabled: var(--color-grey-60);
*/
.KitList-module_base__ojyC- {
  padding: 0;
  margin: 0;
  list-style: none;
}
.KitList-module_item__2z-sW {
  position: relative;
  padding-top: 26px;
  padding-bottom: 26px;
  margin: 0;
  font-family: 'Suisse Medium'
,
sans-serif;
  font-size: 15px;
  line-height: 1.4;
  opacity: 0;
  -webkit-transform: translateY(-20px);
          transform: translateY(-20px);
  transition-duration: 1000ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.KitList-module_item__2z-sW:nth-of-type(1) {
    transition-delay: calc((1 * 100ms) + 200ms);
  }
.KitList-module_item__2z-sW:nth-of-type(2) {
    transition-delay: calc((2 * 100ms) + 200ms);
  }
.KitList-module_item__2z-sW:nth-of-type(3) {
    transition-delay: calc((3 * 100ms) + 200ms);
  }
.KitList-module_item__2z-sW:nth-of-type(4) {
    transition-delay: calc((4 * 100ms) + 200ms);
  }
.KitList-module_dark__JqFfF .KitList-module_item__2z-sW {
    color: #333;
  }
.KitList-module_dark__JqFfF .KitList-module_item__2z-sW:not(:last-child) {
      border-bottom: 1px solid #d5d4c9;
      margin: 0;
    }
.KitList-module_light__1IVl5.KitList-module_light__1IVl5 .KitList-module_item__2z-sW {
    color: #fffef0;
  }
.KitList-module_light__1IVl5.KitList-module_light__1IVl5 .KitList-module_item__2z-sW:not(:last-child) {
      border-bottom: 1px solid #d5d4c9;
      margin: 0;
    }
@media (min-width: 640px) {
.KitList-module_item__2z-sW {
    padding-top: 14px;
    padding-bottom: 14px;
    line-height: 1.6
}
  }
.KitList-module_slideIn__36yxB {
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}

/* @import './key-frame.css'; */
/* @import './font-face.css'; */
:root {
  /* Base colours */

  /* UI colours light theme */

  /* UI colours dark theme */

  /* Background colours */

  /* System colours */
}
/* Dark theme UI elements */
/* Light theme UI elements */
/* UI colours light theme */
/*
--color-light-copy: var(--color-primary);
--color-light-copy-alternate: var(--color-white-darker);
--color-light-label: var(--color-white-darker);
--color-light-link: var(--color-primary);
--color-light-link-hover: var(--color-white-darker);
--color-light-decoration: var(--color-white-darker);
--color-light-disabled: var(--color-grey-60);
*/
/* UI colours dark theme */
/*
--color-dark-copy: var(--color-secondary);
--color-dark-copy-alternate: var(--color-grey-63);
--color-dark-label: var(--color-grey-30);
--color-dark-link: var(--color-grey-20);
--color-dark-link-hover: var(--color-grey-10);
--color-dark-decoration: var(--color-grey-30);
--color-dark-disabled: var(--color-grey-60);
*/
.Loading-module_base__11pIy {
  display: flex;
  width: 100%;
  height: 7px;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
  transition: opacity 50ms cubic-bezier(0.645, 0.045, 0.355, 1);
}
.Loading-module_small__2H7s_ {
  height: 7px;
}
.Loading-module_isLoading__2jmYF {
  opacity: 1;
  transition: opacity 200ms cubic-bezier(0.645, 0.045, 0.355, 1);
}
.Loading-module_dot__2jmbK {
  display: block;
  width: 7px;
  height: 7px;
  margin: 0 3px;
  border-radius: 100%;
}
.Loading-module_small__2H7s_ .Loading-module_dot__2jmbK {
    width: 5px;
    height: 5px;
  }
.Loading-module_dark__2DSO5 .Loading-module_dot__2jmbK {
    background: #4a4a4a;
  }
.Loading-module_light__3WGPG .Loading-module_dot__2jmbK {
    background: #fffef2;
  }
@-webkit-keyframes Loading-module_fadeInOut__2NvtY {
    0% {
      opacity: 0;
    }

    60% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }
@keyframes Loading-module_fadeInOut__2NvtY {
    0% {
      opacity: 0;
    }

    60% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }
.Loading-module_isLoading__2jmYF .Loading-module_dot__2jmbK {
    -webkit-animation: Loading-module_fadeInOut__2NvtY 1000ms cubic-bezier(0.645, 0.045, 0.355, 1) infinite;
            animation: Loading-module_fadeInOut__2NvtY 1000ms cubic-bezier(0.645, 0.045, 0.355, 1) infinite;
  }
.Loading-module_isLoading__2jmYF .Loading-module_dot__2jmbK:nth-of-type(1) {
      -webkit-animation-delay: -50ms;
              animation-delay: -50ms;
    }
.Loading-module_isLoading__2jmYF .Loading-module_dot__2jmbK:nth-of-type(2) {
      -webkit-animation-delay: 50ms;
              animation-delay: 50ms;
    }
.Loading-module_isLoading__2jmYF .Loading-module_dot__2jmbK:nth-of-type(3) {
      -webkit-animation-delay: 150ms;
              animation-delay: 150ms;
    }

:root {
  /* Base colours */

  /* UI colours light theme */

  /* UI colours dark theme */

  /* Background colours */

  /* System colours */
}
/* Dark theme UI elements */
/* Light theme UI elements */
/* UI colours light theme */
/*
--color-light-copy: var(--color-primary);
--color-light-copy-alternate: var(--color-white-darker);
--color-light-label: var(--color-white-darker);
--color-light-link: var(--color-primary);
--color-light-link-hover: var(--color-white-darker);
--color-light-decoration: var(--color-white-darker);
--color-light-disabled: var(--color-grey-60);
*/
/* UI colours dark theme */
/*
--color-dark-copy: var(--color-secondary);
--color-dark-copy-alternate: var(--color-grey-63);
--color-dark-label: var(--color-grey-30);
--color-dark-link: var(--color-grey-20);
--color-dark-link-hover: var(--color-grey-10);
--color-dark-decoration: var(--color-grey-30);
--color-dark-disabled: var(--color-grey-60);
*/
.ModalBody-module_base__3I-jF {
  position: relative;
  overflow: scroll;
  width: 100%;
  max-height: calc(100vh - 16px * 4);
  padding: calc(16px * 2);
  background-color: #fffef2;
  color: #252525;
  font-family: 'Suisse Regular'
,
sans-serif;
  font-size: 15px;
  line-height: 1.6;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
}
.ModalBody-module_base__3I-jF *,
    .ModalBody-module_base__3I-jF *::before,
    .ModalBody-module_base__3I-jF *::after {
      box-sizing: inherit;
    }
@media (min-width: 640px) {
.ModalBody-module_base__3I-jF {
    max-height: calc(100vh - 34px * 4 - 50px);
    padding: calc(34px * 2)
}
  }
.ModalBody-module_base__3I-jF.ModalBody-module_isVisible__1QTme {
    pointer-events: auto;
  }
.ModalBody-module_closeIcon__3aJW5 {
  fill: #252525;
}
.ModalBody-module_closeButton__2UtNd {
  position: absolute;
  top: 16px;
  right: 16px;
  width: 30px;
  height: 30px;
  padding: 2px 4px 0 4px;
}
@media (min-width: 640px) {
.ModalBody-module_closeButton__2UtNd {
    top: 18px;
    right: 20px
}
  }
.ModalBody-module_closeButton__2UtNd:focus {
    outline-color: #4a4a4a;
  }

:root {
  /* Base colours */

  /* UI colours light theme */

  /* UI colours dark theme */

  /* Background colours */

  /* System colours */
}
/* Dark theme UI elements */
/* Light theme UI elements */
/* UI colours light theme */
/*
--color-light-copy: var(--color-primary);
--color-light-copy-alternate: var(--color-white-darker);
--color-light-label: var(--color-white-darker);
--color-light-link: var(--color-primary);
--color-light-link-hover: var(--color-white-darker);
--color-light-decoration: var(--color-white-darker);
--color-light-disabled: var(--color-grey-60);
*/
/* UI colours dark theme */
/*
--color-dark-copy: var(--color-secondary);
--color-dark-copy-alternate: var(--color-grey-63);
--color-dark-label: var(--color-grey-30);
--color-dark-link: var(--color-grey-20);
--color-dark-link-hover: var(--color-grey-10);
--color-dark-decoration: var(--color-grey-30);
--color-dark-disabled: var(--color-grey-60);
*/
.Modal-module_base__1XCSr {
  position: absolute;
  z-index: 100;
  top: 0;
  right: 0;
  left: 0;
}
.Modal-module_base__1XCSr *,
    .Modal-module_base__1XCSr *::before,
    .Modal-module_base__1XCSr *::after {
      box-sizing: inherit;
    }
@media (min-width: 640px) {
.Modal-module_base__1XCSr {
    position: fixed
}
  }
.Modal-module_inner__3QC4Z {
  position: relative;
  z-index: 120;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
@media (min-width: 640px) {
.Modal-module_inner__3QC4Z {
    min-width: 320px;
    max-width: 550px;
    align-items: center;
    margin: 50px auto 0
}
  }

/* @import './key-frame.css'; */
/* @import './font-face.css'; */
:root {
  /* Base colours */

  /* UI colours light theme */

  /* UI colours dark theme */

  /* Background colours */

  /* System colours */
}
/* Dark theme UI elements */
/* Light theme UI elements */
/* UI colours light theme */
/*
--color-light-copy: var(--color-primary);
--color-light-copy-alternate: var(--color-white-darker);
--color-light-label: var(--color-white-darker);
--color-light-link: var(--color-primary);
--color-light-link-hover: var(--color-white-darker);
--color-light-decoration: var(--color-white-darker);
--color-light-disabled: var(--color-grey-60);
*/
/* UI colours dark theme */
/*
--color-dark-copy: var(--color-secondary);
--color-dark-copy-alternate: var(--color-grey-63);
--color-dark-label: var(--color-grey-30);
--color-dark-link: var(--color-grey-20);
--color-dark-link-hover: var(--color-grey-10);
--color-dark-decoration: var(--color-grey-30);
--color-dark-disabled: var(--color-grey-60);
*/
.Quote-module_base__2H3wf {
  display: flex;
  align-items: center;
  padding: 60px 16px;
}
@media (min-width: 640px) {
.Quote-module_base__2H3wf {
    padding: 100px 34px
}
  }
@media (min-width: 1025px) {
.Quote-module_base__2H3wf {
    padding: 160px 40px
}
  }
.Quote-module_dark__ocCjZ {
  color: #333;
}
.Quote-module_light__2dKx5 {
  color: #fffef0;
}
.Quote-module_noTopPadding__x31V0 {
  padding-top: 0;
}
.Quote-module_wrapper__1S4Pe {
  margin: 0 auto;
  text-align: center;
}
@media (min-width: 640px) {
.Quote-module_wrapper__1S4Pe {
    /* flex-basis doesn't account for box-sizing:border-box */
    /* stylelint-disable */
    width: calc(100% * 3 / 6);
    flex-basis: auto
    /* IE is consistently 1px too wide with the standard calc value */
    /* here is a hack so terrible we have to tell the linter to ignore it */
    /* stylelint-enable */
}
    .Quote-module_wrapper__1S4Pe _:-ms-input-placeholder,
  :root .Quote-module_wrapper__1S4Pe {
    width: calc((100% * 3 / 6) - 1px);
  }
  }
@media (min-width: 1025px) {
.Quote-module_wrapper__1S4Pe {
    /* flex-basis doesn't account for box-sizing:border-box */
    /* stylelint-disable */
    width: calc(100% * 6 / 12);
    flex-basis: auto
    /* IE is consistently 1px too wide with the standard calc value */
    /* here is a hack so terrible we have to tell the linter to ignore it */
    /* stylelint-enable */
}
    .Quote-module_wrapper__1S4Pe _:-ms-input-placeholder,
  :root .Quote-module_wrapper__1S4Pe {
    width: calc((100% * 6 / 12) - 1px);
  }
  }
.Quote-module_blockquote__1Ap9l {
  padding: 0;
  margin: 20px 0;
  font-family: 'Zapf Humanist'
,
serif;
  font-size: 27px;
  font-weight: 400;
  line-height: 1.2;
  -webkit-text-size-adjust: 100%;
     -moz-text-size-adjust: 100%;
      -ms-text-size-adjust: 100%;
          text-size-adjust: 100%;
}
.Quote-module_author__3tPnl {
  display: block;
  margin-top: 24px;
  font-family: 'Suisse Medium'
,
sans-serif;
  font-size: 15px;
  font-style: normal;
  font-style: initial;
  line-height: 1.4;
}
@media (min-width: 640px) {
.Quote-module_author__3tPnl {
    margin-top: 34px
}
  }
@media (min-width: 1025px) {
.Quote-module_author__3tPnl {
    margin-top: 48px
}
  }

/* @import './key-frame.css'; */
/* @import './font-face.css'; */
:root {
  /* Base colours */

  /* UI colours light theme */

  /* UI colours dark theme */

  /* Background colours */

  /* System colours */
}
/* Dark theme UI elements */
/* Light theme UI elements */
/* UI colours light theme */
/*
--color-light-copy: var(--color-primary);
--color-light-copy-alternate: var(--color-white-darker);
--color-light-label: var(--color-white-darker);
--color-light-link: var(--color-primary);
--color-light-link-hover: var(--color-white-darker);
--color-light-decoration: var(--color-white-darker);
--color-light-disabled: var(--color-grey-60);
*/
/* UI colours dark theme */
/*
--color-dark-copy: var(--color-secondary);
--color-dark-copy-alternate: var(--color-grey-63);
--color-dark-label: var(--color-grey-30);
--color-dark-link: var(--color-grey-20);
--color-dark-link-hover: var(--color-grey-10);
--color-dark-decoration: var(--color-grey-30);
--color-dark-disabled: var(--color-grey-60);
*/
.TwoColumnLayout-module_base__2yBvR {
  display: flex;
  box-sizing: border-box;
  flex-wrap: wrap;
  justify-content: center;
}
.TwoColumnLayout-module_base__2yBvR *,
    .TwoColumnLayout-module_base__2yBvR *::before,
    .TwoColumnLayout-module_base__2yBvR *::after {
      box-sizing: inherit;
    }
@media (min-width: 1025px) {
.TwoColumnLayout-module_base__2yBvR {
    justify-content: flex-start
}
  }
.TwoColumnLayout-module_sidebar__ahl3O {
  width: calc(100% - 16px * 2);
  margin-bottom: calc(16px * 2);
}
@media (min-width: 640px) {
.TwoColumnLayout-module_sidebar__ahl3O {
    width: calc((100% - 34px * 2) * 5 / 6);
    margin-bottom: calc(34px * 2)
}
  }
@media (min-width: 1025px) {
.TwoColumnLayout-module_sidebar__ahl3O {
    width: calc((100% - 40px * 2) * 5 / 12);
    padding:
      0 40px 0
      calc((100% - 40px * 2) / 12);
    margin-right: calc((100% + 40px * 2) * 1 / 12);
    margin-bottom: 0
}
  }
.TwoColumnLayout-module_content__3aiag {
  width: calc(100% - 16px * 2);
  margin-bottom: calc(16px * 2);
}
@media (min-width: 640px) {
.TwoColumnLayout-module_content__3aiag {
    width: calc((100% - 34px * 2) * 5 / 6);
    margin-bottom: calc(34px * 2)
}
  }
@media (min-width: 1025px) {
.TwoColumnLayout-module_content__3aiag {
    width: calc((100% - 40px) * 6 / 12);
    margin-bottom: 0;
    margin-left: 40px
}
  }
@media (min-width: 640px) {
.TwoColumnLayout-module_content__3aiag.TwoColumnLayout-module_fullWidth__3SACE {
      /* width: 100%;
      padding-left: calc((100% - 34px * 2) / 6); */
  }
    }
@media (min-width: 1025px) {
.TwoColumnLayout-module_content__3aiag.TwoColumnLayout-module_fullWidth__3SACE {
      /* width: calc((100% - 40px * 2) * 7 / 12 + 60px);
      padding-left: 0; */
  }
    }
.TwoColumnLayout-module_content__3aiag.TwoColumnLayout-module_fullWidth__3SACE.TwoColumnLayout-module_reverse__3PqUh {
      /* reverse */
    }
.TwoColumnLayout-module_reverse__3PqUh {
  flex-direction: column-reverse;
  align-content: center;
}
@media (min-width: 1025px) {
.TwoColumnLayout-module_reverse__3PqUh {
    flex-direction: row-reverse;
    justify-content: flex-end
}
  }
@media (min-width: 1025px) {
.TwoColumnLayout-module_reverse__3PqUh .TwoColumnLayout-module_content__3aiag {
      margin-left: 0
  }
    }
@media (min-width: 1025px) {
.TwoColumnLayout-module_reverse__3PqUh .TwoColumnLayout-module_sidebar__ahl3O {
      padding:
        0 calc((100% - 40px * 2) / 12) 0
        40px;
      margin-right: 0
  }
    }
@media (min-width: 1025px) {
.TwoColumnLayout-module_contentOnly__3ilps {
    justify-content: flex-start
}
  }
.TwoColumnLayout-module_contentOnly__3ilps.TwoColumnLayout-module_reverse__3PqUh {
    justify-content: flex-end;
  }
@media (min-width: 1025px) {
.TwoColumnLayout-module_contentOnly__3ilps.TwoColumnLayout-module_reverse__3PqUh .TwoColumnLayout-module_content__3aiag {
        width: calc((100% - 40px) * 6 / 12);
        margin-left: calc((100% - 40px * 2) * 5 / 12)
    }
      }
.TwoColumnLayout-module_verticalPadding__3XZGY {
  padding-top: calc(16px / 1.5);
  padding-bottom: calc(16px / 1.5);
}
@media (min-width: 640px) {
.TwoColumnLayout-module_verticalPadding__3XZGY {
    padding-top: calc(34px / 1.5);
    padding-bottom: calc(34px / 1.5)
}
  }
@media (min-width: 1025px) {
.TwoColumnLayout-module_verticalPadding__3XZGY {
    padding-top: calc(40px / 1.5);
    padding-bottom: calc(40px / 1.5)
}
  }

/* @import './key-frame.css'; */
/* @import './font-face.css'; */
:root {
  /* Base colours */

  /* UI colours light theme */

  /* UI colours dark theme */

  /* Background colours */

  /* System colours */
}
/* Dark theme UI elements */
/* Light theme UI elements */
/* UI colours light theme */
/*
--color-light-copy: var(--color-primary);
--color-light-copy-alternate: var(--color-white-darker);
--color-light-label: var(--color-white-darker);
--color-light-link: var(--color-primary);
--color-light-link-hover: var(--color-white-darker);
--color-light-decoration: var(--color-white-darker);
--color-light-disabled: var(--color-grey-60);
*/
/* UI colours dark theme */
/*
--color-dark-copy: var(--color-secondary);
--color-dark-copy-alternate: var(--color-grey-63);
--color-dark-label: var(--color-grey-30);
--color-dark-link: var(--color-grey-20);
--color-dark-link-hover: var(--color-grey-10);
--color-dark-decoration: var(--color-grey-30);
--color-dark-disabled: var(--color-grey-60);
*/
.TwoColumnList-module_base__rFZFc {
  box-sizing: border-box;
}
.TwoColumnList-module_base__rFZFc *,
    .TwoColumnList-module_base__rFZFc *::before,
    .TwoColumnList-module_base__rFZFc *::after {
      box-sizing: inherit;
    }
@media (min-width: 640px) {
.TwoColumnList-module_base__rFZFc {
    display: flex;
    flex-wrap: wrap
}
  }
.TwoColumnList-module_subHeading__2ubT9 {
  font-family: 'Suisse Medium'
,
sans-serif;
}
.TwoColumnList-module_column__23aK9 {
  padding-right: 16px;
}
@media (min-width: 640px) {
    .TwoColumnList-module_column__23aK9:not(:last-child) {
      padding-right: 16px;
    }
  }
@media (min-width: 1025px) {
    .TwoColumnList-module_column__23aK9:not(:last-child) {
      padding-right: 16px;
    }
  }
@media (min-width: 640px) {
.TwoColumnList-module_column__23aK9 {
    max-width: 50%;
    flex: 0 0 50%
}
  }
.TwoColumnList-module_wrapper__3K7T4 {
  margin-bottom: 16px;
}
@media (min-width: 640px) {
.TwoColumnList-module_wrapper__3K7T4 {
    margin-bottom: 34px
}
  }
@media (--viewport-ld) {
.TwoColumnList-module_wrapper__3K7T4 {
    margin-bottom: var(--layout-ld-spacing)
}
  }
.TwoColumnList-module_dark__2vxrl a {
    /* @extend %font-theme-dark-link; */
    color: #4a4a4a;
  }
.TwoColumnList-module_dark__2vxrl a:hover,
    .TwoColumnList-module_dark__2vxrl a:focus {
      color: #252525;
    }
.TwoColumnList-module_light__bZ60S.TwoColumnList-module_light__bZ60S a {
    /* @extend %font-theme-light-link; */
    color: #fffef0;
  }
.TwoColumnList-module_light__bZ60S.TwoColumnList-module_light__bZ60S a:hover,
    .TwoColumnList-module_light__bZ60S.TwoColumnList-module_light__bZ60S a:focus {
      color: #f6f5e8;
    }
.TwoColumnList-module_list__FcSTT {
  font-size: 15px;
  /* stylelint-enable */
}
/* stylelint-disable */
.TwoColumnList-module_list__FcSTT li a {
      font-size: 15px;
      text-decoration: none;
    }
.TwoColumnList-module_list__FcSTT li a:hover,
      .TwoColumnList-module_list__FcSTT li a:focus {
        text-decoration: underline;
      }
.TwoColumnList-module_list__FcSTT li li:not(:last-child) {
        margin-bottom: 10px;
      }

/* @import './key-frame.css'; */
/* @import './font-face.css'; */
:root {
  /* Base colours */

  /* UI colours light theme */

  /* UI colours dark theme */

  /* Background colours */

  /* System colours */
}
/* Dark theme UI elements */
/* Light theme UI elements */
/* UI colours light theme */
/*
--color-light-copy: var(--color-primary);
--color-light-copy-alternate: var(--color-white-darker);
--color-light-label: var(--color-white-darker);
--color-light-link: var(--color-primary);
--color-light-link-hover: var(--color-white-darker);
--color-light-decoration: var(--color-white-darker);
--color-light-disabled: var(--color-grey-60);
*/
/* UI colours dark theme */
/*
--color-dark-copy: var(--color-secondary);
--color-dark-copy-alternate: var(--color-grey-63);
--color-dark-label: var(--color-grey-30);
--color-dark-link: var(--color-grey-20);
--color-dark-link-hover: var(--color-grey-10);
--color-dark-decoration: var(--color-grey-30);
--color-dark-disabled: var(--color-grey-60);
*/
@media (min-width: 1025px) {
.Controls-module_fullScreenControls__2TWMJ {
    position: fixed;
    z-index: 1500;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
    transition: all 800ms
}
  }
.Controls-module_videoTitle__1DWab {
  position: absolute;
  top: calc(16px * 2);
  left: calc(16px * 2);
}
@media (min-width: 640px) {
.Controls-module_videoTitle__1DWab {
    top: calc(34px * 2);
    left: calc(34px * 2)
}
  }
@media (min-width: 1025px) {
.Controls-module_videoTitle__1DWab {
    top: calc(40px * 2);
    left: calc(40px * 2)
}
  }
.Controls-module_fullScreenControlsHeader__K_5Jt {
  opacity: 1;
  pointer-events: auto;
  transition: opacity 500ms cubic-bezier(0.47, 0, 0.745, 0.715);
}
.Controls-module_activeFullScreenControls__3gh8o.Controls-module_activeFullScreenControls__3gh8o {
  pointer-events: auto;
  -webkit-transform: scale(1);
          transform: scale(1);
}
.Controls-module_mute__Zo2HR {
  position: absolute;
  z-index: calc(1500 + 1);
  top: calc(16px * 2);
  left: calc(16px * 2);
  padding: 7px;
  background-color: #6b6b6b;
  border-radius: 100%;
  line-height: 0;
  opacity: 0.8;
  transition: opacity 800ms cubic-bezier(0.47, 0, 0.745, 0.715);
}
.Controls-module_mute__Zo2HR:hover,
  .Controls-module_mute__Zo2HR:focus {
    opacity: 1;
  }
@media (min-width: 640px) {
.Controls-module_mute__Zo2HR {
    top: calc(34px * 2);
    left: calc(34px * 2)
}
  }
@media (min-width: 1025px) {
.Controls-module_mute__Zo2HR {
    top: calc(40px * 2);
    left: calc(40px * 2)
}
  }
.Controls-module_close__2aDWj {
  position: absolute;
  top: calc(16px * 2);
  right: calc(16px * 2);
  padding-right: 4px;
  color: #fffef0;
  font-size: 17px;
  opacity: 0.8;
  transition: opacity 200ms cubic-bezier(0.47, 0, 0.745, 0.715);
}
.Controls-module_close__2aDWj:hover,
  .Controls-module_close__2aDWj:focus {
    opacity: 1;
  }
.Controls-module_close__2aDWj svg {
    fill: #fffef0;
  }
.Controls-module_close__2aDWj:hover svg,
  .Controls-module_close__2aDWj:focus svg {
    fill: #f6f5e8;
  }
@media (min-width: 640px) {
.Controls-module_close__2aDWj {
    top: calc(34px * 2);
    right: calc(34px * 2)
}
  }
@media (min-width: 1025px) {
.Controls-module_close__2aDWj {
    top: calc(40px * 2);
    right: calc(40px * 2)
}
  }
.Controls-module_playPauseButton__3KMud {
  position: absolute;
  top: calc(50% - 15px);
  left: calc(50% - 15px);
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #f6f5e8;
  border-radius: 100%;
  color: #fffef0;
  opacity: 0.8;
  transition: opacity 800ms cubic-bezier(0.47, 0, 0.745, 0.715);
}
.Controls-module_playPauseButton__3KMud:hover,
  .Controls-module_playPauseButton__3KMud:focus {
    color: #f6f5e8;
    opacity: 1;
  }
.Controls-module_activePlayPauseButton__3vXZv.Controls-module_activePlayPauseButton__3vXZv {
  background-color: #6b6b6b;
}
.Controls-module_activePlayPauseButton__3vXZv.Controls-module_activePlayPauseButton__3vXZv svg {
    fill: #fffef0;
  }
.Controls-module_inactivePlayPauseButton__2r-aT {
  display: none;
}
.Controls-module_fullScreenPlayPauseButton__2qnFo.Controls-module_fullScreenPlayPauseButton__2qnFo {
  position: absolute;
  z-index: 1500;
  top: calc(50vh - 40px - 13px);
  left: calc(50vw - 40px - 13px);
  padding: 10px;
  background-color: #6b6b6b;
  border-radius: 100%;
  line-height: 0;
  opacity: 0.8;
  transition: opacity 200ms cubic-bezier(0.47, 0, 0.745, 0.715);
}
.Controls-module_fullScreenPlayPauseButton__2qnFo.Controls-module_fullScreenPlayPauseButton__2qnFo svg {
    fill: #fffef0;
  }
.Controls-module_fullScreenPlayPauseButton__2qnFo.Controls-module_fullScreenPlayPauseButton__2qnFo:hover,
  .Controls-module_fullScreenPlayPauseButton__2qnFo.Controls-module_fullScreenPlayPauseButton__2qnFo:focus {
    opacity: 0.9;
  }
.Controls-module_playPauseButtonIcon__3Jt62.Controls-module_playPauseButtonIcon__3Jt62 {
  display: flex;
  width: 30px;
  height: 30px;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  background-color: #fffef0;
  border-radius: 100%;
}
.Controls-module_playPauseButtonIcon__3Jt62.Controls-module_playPauseButtonIcon__3Jt62:hover,
  .Controls-module_playPauseButtonIcon__3Jt62.Controls-module_playPauseButtonIcon__3Jt62:focus {
    color: #f6f5e8;
  }
.Controls-module_playPauseButtonIcon__3Jt62.Controls-module_playPauseButtonIcon__3Jt62 svg {
    fill: #333;
  }
.Controls-module_playButtonIcon__3uHZQ {
  -webkit-transform: translateX(1px);
          transform: translateX(1px);
}
.Controls-module_progressBar__2qSO3 {
  position: absolute;
  z-index: 1500;
  right: calc(40px * 2);
  bottom: 90px;
  left: calc(40px * 2);
  display: block;
  overflow: hidden;
  height: 3px;
  margin: 0;
  background-color: rgba(255, 255, 255, 0.2);
}
@media (max-width: 639px) {
.Controls-module_progressBar__2qSO3 {
    right: calc(16px * 2);
    bottom: 30px;
    left: calc(16px * 2)
}
  }
@media (min-width: 640px) and (max-width: 1024px) {
.Controls-module_progressBar__2qSO3 {
    right: calc(34px * 2);
    bottom: 48px;
    left: calc(34px * 2)
}
  }
@media (min-width: 1025px) {
.Controls-module_progressBar__2qSO3 {
    position: fixed
}
  }
.Controls-module_progress__3LuK- {
  width: 0;
  height: 3px;
  background-color: #fffef2;
  content: '';
  transition: width 100ms cubic-bezier(0.47, 0, 0.745, 0.715);
}
.Controls-module_hidden__3_owA.Controls-module_hidden__3_owA {
  opacity: 0;
  pointer-events: none;
}

/* @import './key-frame.css'; */
/* @import './font-face.css'; */
:root {
  /* Base colours */

  /* UI colours light theme */

  /* UI colours dark theme */

  /* Background colours */

  /* System colours */
}
/* Dark theme UI elements */
/* Light theme UI elements */
/* UI colours light theme */
/*
--color-light-copy: var(--color-primary);
--color-light-copy-alternate: var(--color-white-darker);
--color-light-label: var(--color-white-darker);
--color-light-link: var(--color-primary);
--color-light-link-hover: var(--color-white-darker);
--color-light-decoration: var(--color-white-darker);
--color-light-disabled: var(--color-grey-60);
*/
/* UI colours dark theme */
/*
--color-dark-copy: var(--color-secondary);
--color-dark-copy-alternate: var(--color-grey-63);
--color-dark-label: var(--color-grey-30);
--color-dark-link: var(--color-grey-20);
--color-dark-link-hover: var(--color-grey-10);
--color-dark-decoration: var(--color-grey-30);
--color-dark-disabled: var(--color-grey-60);
*/
.Poster-module_base__1YXtU {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
}
.Poster-module_base__1YXtU,
  .Poster-module_base__1YXtU picture,
  .Poster-module_base__1YXtU img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
.Poster-module_isActive__2lxc1 {
  pointer-events: auto;
}

/* @import './key-frame.css'; */
/* @import './font-face.css'; */
:root {
  /* Base colours */

  /* UI colours light theme */

  /* UI colours dark theme */

  /* Background colours */

  /* System colours */
}
/* Dark theme UI elements */
/* Light theme UI elements */
/* UI colours light theme */
/*
--color-light-copy: var(--color-primary);
--color-light-copy-alternate: var(--color-white-darker);
--color-light-label: var(--color-white-darker);
--color-light-link: var(--color-primary);
--color-light-link-hover: var(--color-white-darker);
--color-light-decoration: var(--color-white-darker);
--color-light-disabled: var(--color-grey-60);
*/
/* UI colours dark theme */
/*
--color-dark-copy: var(--color-secondary);
--color-dark-copy-alternate: var(--color-grey-63);
--color-dark-label: var(--color-grey-30);
--color-dark-link: var(--color-grey-20);
--color-dark-link-hover: var(--color-grey-10);
--color-dark-decoration: var(--color-grey-30);
--color-dark-disabled: var(--color-grey-60);
*/
.VideoPlayer-module_base__1NW0T {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media (min-width: 1025px) {
.VideoPlayer-module_playsInFullScreen__2grcD {
    position: fixed;
    z-index: 1500;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
    transition: all 600ms
}
  }
.VideoPlayer-module_isPlayingInFullScreen__2kBK1.VideoPlayer-module_isPlayingInFullScreen__2kBK1 {
  pointer-events: auto;
  -webkit-transform: scale(1);
          transform: scale(1);
}

/* @import './key-frame.css'; */
/* @import './font-face.css'; */
:root {
  /* Base colours */

  /* UI colours light theme */

  /* UI colours dark theme */

  /* Background colours */

  /* System colours */
}
/* Dark theme UI elements */
/* Light theme UI elements */
/* UI colours light theme */
/*
--color-light-copy: var(--color-primary);
--color-light-copy-alternate: var(--color-white-darker);
--color-light-label: var(--color-white-darker);
--color-light-link: var(--color-primary);
--color-light-link-hover: var(--color-white-darker);
--color-light-decoration: var(--color-white-darker);
--color-light-disabled: var(--color-grey-60);
*/
/* UI colours dark theme */
/*
--color-dark-copy: var(--color-secondary);
--color-dark-copy-alternate: var(--color-grey-63);
--color-dark-label: var(--color-grey-30);
--color-dark-link: var(--color-grey-20);
--color-dark-link-hover: var(--color-grey-10);
--color-dark-decoration: var(--color-grey-30);
--color-dark-disabled: var(--color-grey-60);
*/
.Video-module_base__3wxiY {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 0;
  margin: 0;
  object-fit: cover;
}
@media (max-width: 1024px) {
.Video-module_base__3wxiY {
    height: 0;
    padding-top: 30px;
    padding-bottom: 56.25% /* 16:9 */
}
  }
@media (min-width: 1025px) {
.Video-module_base__3wxiY {
    height: auto;
    padding-top: 0;
    padding-bottom: 100%
}
  }
@media (min-width: 1025px) {
.Video-module_fullWidth__3pl8F {
    height: 0;
    padding-top: 30px;
    padding-bottom: 56.25% /* 16:9 */
}
  }
.Video-module_fallbackImage__38kdv {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.Video-module_fallbackImage__38kdv,
  .Video-module_fallbackImage__38kdv picture,
  .Video-module_fallbackImage__38kdv img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

/*# sourceMappingURL=styles.css.map */
