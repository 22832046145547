@import 'styles/variables/index';
@import 'styles/mixins/index';

.base {
  position: fixed;
  z-index: var(--z-index-fixed-dialog-banner);
  right: 0;
  left: 0;
  padding: 1.5rem rem(40px);

  @media (--viewport-sm-only) {
    padding: 1.5rem 1.5rem 2.2rem 1.5rem;
  }

  @media (--viewport-md) {
    display: grid;
    grid-template-columns: 15% 63% 22%;
  }

  @media (--viewport-lg) {
    grid-template-columns: 20% 60% 20%;
  }
}

.top {
  top: 0;
}

.bottom {
  @extend %slideOutDown;

  bottom: 0;
}

/* @TODO Handle bottom prop */
.isVisible {
  &.bottom {
    @extend %slideInUp;
  }
}

.dark {
  background-color: var(--color-secondary);
}

.black {
  background-color: var(--color-grey-10);
}

.contentContainer {
  @media (--viewport-md) {
    padding: 0 rem(24px);
  }
}

.heading {
  color: var(--color-white);

  @media (--viewport-sm-only) {
    margin-top: rem(28px);
    margin-bottom: rem(10px);
  }
}

.message {
  color: var(--color-grey-65);
  font-size: rem(15px);

  @media (--viewport-sm-only) {
    &:last-of-type {
      margin-bottom: rem(14px);
    }
  }
}

.warningIcon {
  fill: #999;
}

.closeButton {
  position: absolute;
  top: rem(1.75rem);
  right: rem(2.5rem);

  @media (--viewport-sm-only) {
    top: rem(1.5rem);
    right: rem(1.5rem);
  }

  &:hover,
  &:focus {
    svg {
      fill: white;
    }
  }
}

.closeIcon {
  fill: #999;
}

.closeContainer {
  @media (--viewport-md) {
    align-self: flex-end;
    text-align: right;
  }
}

.ctaButton {
  font-size: rem(15px);
}
