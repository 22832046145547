@import 'styles/variables/index';

.shiftInDownEnter {
  opacity: 0;
  transform: translate3d(0, -20px, 0);
}

.shiftInDownEnterActive {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition:
    transform 1500ms var(--easing-ease-out-cubic),
    opacity 1500ms var(--easing-ease-in-cubic);
}

.shiftInDownEnterDone {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.shiftInDownExit {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.shiftInDownExitActive {
  opacity: 0;
  transform: translate3d(0, -20px, 0);
  transition:
    transform 1500ms var(--easing-ease-out-cubic),
    opacity 1500ms var(--easing-ease-in-cubic);
}

.shiftInDownExitDone {
  opacity: 0;
  transform: translate3d(0, -20px, 0);
}
