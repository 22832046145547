@import 'styles/index';

.base {
  position: absolute;
  z-index: var(--z-index-carousel-navigation);
  top: calc(50% - 80px);
  right: 0;
  display: flex;
  width: 60px;
  height: 60px;
  align-items: center;
  justify-content: center;
  background-color: var(--color-secondary);
  pointer-events: auto;
  transition: all 500ms var(--easing-ease-out-cubic);

  @media (--viewport-lg) {
    width: 80px;
    height: 80px;
    transform: translate3d(80px, 0, 0);
  }

  svg {
    fill: var(--color-white);
    transform: rotate(-90deg);
  }
}

.hidden {
  pointer-events: none;
  transform: translate3d(80px, 0, 0) !important;
}

:global {
  .slick-slider:hover {
    :local {
      .base {
        transform: translate3d(0, 0, 0);
      }
    }
  }
}
