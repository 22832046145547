@import 'styles/variables/index';
@import 'styles/mixins/index';

.base {
  position: absolute;
  z-index: var(--z-index-modal);
  top: 0;
  right: 0;
  left: 0;

  * {
    &,
    &::before,
    &::after {
      box-sizing: inherit;
    }
  }

  @media (--viewport-md) {
    position: fixed;
  }
}

.inner {
  position: relative;
  z-index: var(--z-index-modal-inner);
  display: flex;
  align-items: flex-start;
  justify-content: center;

  @media (--viewport-md) {
    min-width: 320px;
    max-width: 550px;
    align-items: center;
    margin: 50px auto 0;
  }
}
