@import 'styles/index';

.base {
  position: relative;
  font-family: var(--font-family-sans-serif-regular);
  font-size: inherit;
  font-weight: var(--font-weight-normal);
  outline: none;
  text-decoration: none;
}

.hasIcon {
  padding-right: 26px;
}

.icon {
  position: absolute;
  top: calc(50% - 8px);
  right: 0;
  display: inline-block;

  svg {
    width: rem(15px);
    max-height: rem(15px);

    path {
      transition: fill 150ms var(--easing-ease-in-out-cubic);
    }
  }
}

.blockStyle {
  display: inline-block;
  min-width: 210px;
  max-width: 400px;
  padding: 20px;
  border-width: 1px;
  border-style: solid;
  transition: all 200ms var(--easing-ease-in-out-cubic);

  &.hasIcon {
    padding-right: 55px;
  }

  .icon {
    position: absolute;
    top: calc(50% - 8px);
    right: 23px;
  }
}

.dark {
  @extend %font-theme-dark-link;

  .icon {
    svg {
      path {
        fill: var(--color-dark-background);
      }
    }
  }

  &.blockStyle {
    .icon {
      svg {
        path {
          fill: var(--color-dark-background);
        }
      }
    }

    &:hover,
    &:focus {
      border-color: var(--color-dark-background);
      background-color: var(--color-dark-background);
      color: var(--color-light-link-hover);

      .icon {
        svg {
          path {
            fill: var(--color-light-link-hover);
          }
        }
      }
    }
  }
}

.light.light {
  @extend %font-theme-light-link;

  .icon.icon {
    svg {
      path {
        fill: var(--color-white);
      }
    }
  }

  &.blockStyle {
    border: 1px solid var(--color-white);

    .icon {
      svg {
        path {
          fill: var(--color-white);
        }
      }
    }

    &:focus,
    &:hover {
      background-color: var(--color-white);
      color: var(--color-dark-copy);

      .icon {
        svg {
          path {
            fill: var(--color-dark-copy);
          }
        }
      }
    }
  }
}
